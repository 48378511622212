import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Navinner from "../components/nav-inner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import nextImg from "../assets/images/side-icon.svg";
import nextImg1 from "../assets/images/side-icon2.svg";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Innerpagenavbar from "../components/innerpage-navbar";
import Settingpage from "../components/dashboard/settingpage";
import Webinarpage from "./express/schudule/webinar";
import {
  Create_Webinar,
  create_Meeting,
  delete_Webinar,
  searchMeetings,
} from "../action/user.action";
import { useDispatch, useSelector } from "react-redux";
import { encryptString } from "../helper/helper";
import {
  Search_Details,
  setWebinar,
  Webinar_Details,
} from "../redux/features/webinar_details";

import moment, { now } from "moment";

import config from "../lib/config";

import Innerfooter from "../components/inner-footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faShareFromSquare,
  faMagnifyingGlass,
  faListUl,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import Emptymeeting from "../assets/images/Emptymeeting.png";

//COMPONENTS
import ConfirmationModal from "../components/common/modals/ConfirmationModal";
import { toastAlert } from "../utils/toast";
import PropagateLoader from "react-spinners/PropagateLoader";

import { formatInTimeZone } from "date-fns-tz";



const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};

const MyWebinar = () => {
  const [showContent1, setShowContent1] = useState(true);
  const [showContent2, setShowContent2] = useState(false);

  const toggleContent1 = () => {
    setShowContent1(true);
    setShowContent2(false);
  };

  const toggleContent2 = () => {
    setShowContent1(false);
    setShowContent2(true);
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const Copyref = useRef();

  const [MeetingDeatils, setMeetingDeatils] = useState()

  const Meeting_Deatils = useSelector((state) => state.Webinar);
  console.log(Meeting_Deatils, "MeetingDeatils");

  const user_details=useSelector((state)=>state.User)

  const getMeetingDetails = async () => {
    setLoader(true)

    if (Meeting_Deatils) {

      setMeetingDeatils(Meeting_Deatils)

      setLoader(false)
    }
  }
  useEffect(() => {
    getMeetingDetails()
    console.log("MeetingDeatils====",MeetingDeatils)
  }, [Meeting_Deatils])







  //const [MeetingDeatils, setMeetingDetails] = useState(useSelector((state) => state.Webinar));

  // useEffect(() => {
  //     //Implementing the setInterval method
  //     const interval = setInterval(() => {
  //     }, 60000);

  //     //Clearing the interval
  //     return () => clearInterval(interval);
  // }, [MeetingDeatils]);

  const [initialState, setinitialState] = useState({ configure: "", type: "" });

  //STATE
  //Delete webinar list

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [delMeetingId, setDelMeetingId] = useState(null);
  const confirmationConfig = {
    title: "Delete",
    message: "Are you sure want to delete the meeting?",
  };

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [activeModel, setActiveModel] = useState(null);
  const [activeBody, setActiveBody] = useState(1);
  const [copyValue, setCopyValue] = useState();
  const [defaulttemplate, setdefaultTemplate] = useState();

  const [loader, setLoader] = useState(false);

  // const [meeting, setMeeting] = useState({});
  const [WebinarId, setWebinarId] = useState();
  const [searchMeetingTitle, setSearchMeetingTitle] = useState("");

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);

  const handleShow = () => setShow(true);

  const handleShow1 = async (list, webinar) => {
    console.log(list, "createMeeting", webinar);

    let payload;
    if (list.mettingType == 0) {
      payload = {
        id: list._id,
        type: list.mettingType,
      };
    } else {
      payload = {
        id: list._id,
        type: 1,
      };
    }

    console.log(payload, "createMeeting");

    setdefaultTemplate(webinar.templateId);

    payload = JSON.stringify(payload);
    const dat = await encryptString(payload, true);
    // return
    setWebinarId(dat);
    setShow1(true);
  };

  const copyClipboard = () => {
    console.log(defaulttemplate, "defaulttemplate");
    let value;
    if (defaulttemplate) {
      value = `${config.front_URL}/enroll/${WebinarId}`;
    } else {
      value = `${config.front_URL}/attendeesRegister/${WebinarId}`;
    }
    navigator.clipboard.writeText(value);
    setCopyValue(value);
  };

  const handleBodyClick = (bodyId) => {
    setActiveBody(bodyId);
  };

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleModelClick = (model) => {
    setinitialState({ ...initialState, configure: model });
    setActiveModel(model);

    setShow(false);
    setshowconfig(true);

    // if (model) {
    //   confighandleShow()
    // }
  }; //1model
  const [showconfig, setshowconfig] = useState(false);

  const [activeModel1, setActiveModel1] = useState(null);

  //Useeffect
  useEffect(() => {
    // if(searchMeetingTitle!==""){
    dispatch(Search_Details({ name: searchMeetingTitle }));

    console.log("Search_Details",Search_Details);
    // }
  }, [searchMeetingTitle]);

  const confighandleClose = () => setshowconfig(false);

  const confighandleShow = () => {
    if (activeModel != null) {
      setShow(false);
      setshowconfig(true);
    }
    return false;
  };

  const handleModelClick1 = async (model1) => {
    console.log(model1, "model1");

    setinitialState({ ...initialState, type: model1 });

    setActiveModel1(model1);

    const dat = await encryptString(
      JSON.stringify({ ...initialState, type: model1 }),
      true
    );

    if (initialState.configure == "express") {
      // if (model1 == 'Instant Meeting') {

      navigate(`/NewBasicSettings/${dat}`);
      // } else {
      // navigate(`/express`, { state: dat });
      // }
    } else {
      navigate(`/configSettings/config/${dat}`);

      // navigate(`/configSettings/`, { state: { result: dat } });
    }
  }; //2model

  console.log(activeModel, initialState, "initialState");

  const confighandleShowonfig = async () => {
    if (activeModel != null) {
      setshowconfig(false);

      // const { status, result } = await Create_Webinar(initialState)

      // if (status) {
      // dispatch(setWebinar({}));
    }
    return false;
  };

  //Delete - vaffix meeting functions
  const deleteMeeting = (meetingID) => {
    setDelMeetingId(meetingID);
    setShowConfirmationModal(true);
  };

  const hideConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const submitDelete = async (id) => {
    setLoader(true);
    const payload = {
      id: id,
    };
    const { status, result } = await delete_Webinar(payload);
    if (status) {
      dispatch(Webinar_Details());
      toastAlert("success", "Deleted Successfully");
    }
    setShowConfirmationModal(false);
    setLoader(false);
  };

  const createMeeting = async (list) => {
    let payload;
    if (list.mettingType == 0) {
      payload = {
        id: list._id,
        type: list.mettingType,
      };
    } else {
      payload = {
        id: list._id,
        type: 1,
      };
    }

    console.log(payload, "createMeeting");

    payload = JSON.stringify(payload);
    const dat = await encryptString(payload, true);
    // return
    console.log(dat, "dat");
    navigate(`/last-minute/step1/${dat}`);
  };

  // const createMeeting = async (id) => {
  //   const dat = await encryptString(id, true);
  //   navigate(`/last-minute/step1/${dat}`);
  // };

  //search function
  // const search_meetings = async(title) =>{
  //   try {
  //     const Payload = {
  //       name: title,
  //     };
  //     setLoader(true);
  //    await
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  //schedule meeting - start meeting validation

  const schStartMeeting = (
    currentSessionDate,
    nextSessionDate,
    index,
    sessions,
    sessionLen
  ) => {
    console.log(
      currentSessionDate,
      nextSessionDate,
      index,
      sessions,
      sessionLen,
      "schStartMeeting"
    );
    let currSessionDate = new Date(currentSessionDate);
    let nxtSessionDate = new Date(nextSessionDate);

    const CURRDATE = Date.now();

    let response = false;

    // console.log(currSessionDate>CURRDATE)

    if (sessionLen === 1) {
      response = true;
    } else {
      if (index === 0) {
        response =
          currSessionDate >= CURRDATE || nxtSessionDate >= CURRDATE
            ? true
            : false;
      } else {
        if (index === sessionLen - 1) {
          console.log(
            currSessionDate <= CURRDATE,
            sessions[index - 1]?.startDate > CURRDATE,
            "schStartMeeting"
          );
          response =
            // true
            currSessionDate <= CURRDATE ||
            sessions[index - 1]?.startDate < CURRDATE;
        } else {
          response =
            (currSessionDate <= CURRDATE && nxtSessionDate >= CURRDATE) ||
              (currSessionDate <= CURRDATE &&
                nxtSessionDate >= CURRDATE &&
                sessions[index - 1]?.status === 2)
              ? true
              : false;
        }
      }
    }

    // console.log(response, 'schStartMeeting')
    return response;
  };

  const InfoHandler = async (id, type) => {
    console.log(id, "WebinarAnalytics", type);
    
    navigate("/WebinarAnalytics", { state: { result: id, type: type } });

    console.log("first")
  };

  // const convertTime = (sessiondate, timezone) => {
  //   console.log("sessiondate=======",sessiondate);
  //   console.log("timezone=========",timezone);
  //   console.log(sessiondate,timezone,new Date(sessiondate),"hhhhhhhhhhhhhhhhhhhhhhhh------------------");

  //   const pacificMidwayDateTime = moment.tz(new Date(sessiondate),"MM/DD/YYYY hh:mm A", timezone);

  //     console.log("pacificMidwayDateTime=====",pacificMidwayDateTime)

  //   console.log( pacificMidwayDateTime.format("MM/DD/YYYY hh:mm A"),"istDateTime1");

  //   return pacificMidwayDateTime.format("MM/DD/YYYY hh:mm A");
  // };

  

  const convertTime = (sessiondate, timezone) => {
    console.log("sessiondate=======", sessiondate);
    console.log("timezone=========", timezone);
  
    // Parse the sessiondate assuming it's in ISO 8601 format and in UTC
    const originalDateTime = moment.utc(sessiondate).tz(timezone);
    console.log("originalDateTime=====", originalDateTime.format());
  
    // Format to the desired output
    const formattedDate = originalDateTime.format("MM/DD/YYYY hh:mm A");
  
    console.log("formattedDate----====", formattedDate);
  
    return formattedDate;
  };
  

  return (
    <>
      {loader && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#0d2a35"}
            loading={loader}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <div className="dashboard-page">
        <div className="dashboard">
          <Innerpagenavbar />

          <div className="dashbord-right mt-5 mb-5">
            <div className="dashbord-right-body-1 dashboard-padding">
              <div className="dashbord-right-header">
                <p>My webinars</p>
                <Link to="#" className="primary_btn" onClick={handleShow}>
                  <img
                    src={require("../assets/images/chevron-right.png")}
                    height="16"
                    width="16"
                    className="img-fluid"
                  />{" "}
                  Add Webinar
                </Link>
              </div>
              <div className="grid_Row_list">
                <InputGroup className="">
                  <Form.Control
                    placeholder="Search"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    onChange={(e) => setSearchMeetingTitle(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputGroup.Text>
                </InputGroup>
                <div className="icon_grid_list">
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Grid view</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faTh}
                      onClick={toggleContent2}
                      className={`icon_listGrid ${showContent2 ? "active" : ""}`}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">List view</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faListUl}
                      onClick={toggleContent1}
                      className={`icon_listGrid ${showContent1 ? "active" : ""}`}
                    />
                  </OverlayTrigger>
                </div>
              </div>



              {showContent1 && (
                <>
                  {MeetingDeatils?.length > 0 ? (
                    <>
                      {MeetingDeatils.map((list) => (
                        <>
                          <div className="dashbord-right-body mb-3">
                            <div className="dashbordrightbody-header dashbordrightbody-header1">
                              <p>{list?.title}</p>
                              <div>
                                <ul>

                                  <li>
                                    <a
                                      href="#"
                                    // onClick={() => deleteMeeting(list._id)}
                                    >
                                      Count:{list.AttendeeUser?.[0]?.TotalUserCount ? list.AttendeeUser?.[0]?.TotalUserCount : 0}
                                    </a>
                                  </li>
                                  {list?.status != 2 ? (



                                    <li>
                                      <a
                                        href={
                                          list.configType == 0
                                            ? `/express-edit/${list._id}`
                                            : `/configSettings/${list._id}`
                                        }
                                      >
                                        Edit
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  {/* <li>
                              <a href="#">Clone</a>
                            </li> */}
                                  <li>
                                    <a
                                      href="#"
                                      onClick={() => deleteMeeting(list._id)}
                                    >
                                      Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            {list?.mettingType === 1 ||
                              list?.mettingType === 2 ? (
                              <>
                                {list?.sessions?.map(function (item, index) {
                                  let currentSessionDate = new Date(
                                    item?.convertedDate
                                  );
                                  let nextSessionDate = new Date(
                                    list?.sessions?.[index + 1]?.convertedDate
                                  );
                                  // list?.sessions?.[index + 1]?.startDate;
                                  return (
                                    <>
                                      <div className="dashbordrightbody-body">
                                        <div className="dashbordrightbody-list1">
                                          <div className="dashbordrightbody-list">
                                            <div className="dashbordrightbody-content">
                                              <img
                                                src={require("../assets/images/schedule-img.png")}
                                                alt="img"
                                                className="img-fluid"
                                              />

                                              {/*   <img
                                                src={require("../assets/images/schedulemeeting-img.png")}
                                                alt="img"
                                                className="img-fluid"
                                              /> */}
                                              <p>
                                                Session Time:
                                                {
                                                  convertTime(item?.startDate, item?.timeZone)
                                                }

                                                {/* {moment(item?.startDate).format(
                                                  "LLLL"
                                                )} */}
                                                {/* {formatInTimeZone(new Date(item?.startDate), item.timeZone, 'yyyy-MM-dd hh:mm a')
                                                } */}
                                              </p>
                                              <p>
                                                TimeZone:
                                                {item.timeZone}
                                              </p>
                                            </div>
                                            <div className="dashboard_right_body_1">
                                              {item?.status != 2 && (
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Share attendee link</Tooltip>}>
                                                  <div className="share_icon">
                                                    <FontAwesomeIcon
                                                      icon={faShareFromSquare}
                                                      onClick={() =>
                                                        handleShow1(item, list)
                                                      }
                                                    />
                                                  </div>
                                                </OverlayTrigger>
                                              )}

                                              {schStartMeeting(
                                                currentSessionDate,
                                                nextSessionDate,
                                                index,
                                                list?.sessions,
                                                list?.sessions?.length
                                              ) && item?.status !== 2 ? (
                                                <>
                                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Start meeting</Tooltip>}>
                                                    <Link
                                                      to="#"
                                                      className="primary_btn"
                                                      onClick={() =>
                                                        createMeeting(item)
                                                      }
                                                    >
                                                      <img
                                                        src={require("../assets/images/chevron-right.png")}
                                                        height="16"
                                                        width="16"
                                                        className="img-fluid"
                                                      />
                                                      Start
                                                    </Link>
                                                  </OverlayTrigger>
                                                </>
                                              ) : (
                                                <>
                                                  {item?.status === 2 && (
                                                    <button
                                                      // to=""
                                                      className="primary_btn"
                                                      onClick={() =>
                                                        InfoHandler(item._id, 1)
                                                      }
                                                    >
                                                      <img
                                                        src={require("../assets/images/chevron-right.png")}
                                                        height="16"
                                                        width="16"
                                                        className="img-fluid"
                                                      />
                                                      Info
                                                    </button>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}

                                {list?.sessions?.length == 0 ||
                                  (list.configType == 1 && !list.templateId) ? (
                                  <div className="incomplete-notify">
                                    <img
                                      src={require("../assets/images/warning.png")}
                                      alt="img"
                                      className="img-fluid"
                                    />
                                    <p>Configuration Incomplete</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <>
                                <div className="dashbordrightbody-body">
                                  <div className="dashbordrightbody-list1">
                                    <div className="dashbordrightbody-list">
                                      <div className="dashbordrightbody-content">
                                        <img
                                          src={require("../assets/images/instant_img.png")}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                        {/* <img
                                                src={require("../assets/images/schedule-img.png")}
                                                alt="img"
                                                className="img-fluid"
                                              />
                                              <img
                                                src={require("../assets/images/schedulemeeting-img.png")}
                                                alt="img"
                                                className="img-fluid"
                                              /> */}
                                        <p>
                                          Session Time:
                                          {moment(list?.createdAt).format(
                                            "LLLL"
                                          )}
                                        </p>
                                      </div>
                                      <div className="dashboard_right_body_1">
                                        {list?.status === 0 ? (
                                          <>
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Share attendee link</Tooltip>}>
                                              <div className="share_icon">
                                                <FontAwesomeIcon
                                                  icon={faShareFromSquare}
                                                  onClick={() =>
                                                    handleShow1(list, list)
                                                  }
                                                />
                                              </div>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Start meeting</Tooltip>}>
                                              <Link
                                                to="#"
                                                className="primary_btn"
                                                onClick={() =>
                                                  createMeeting(list)
                                                }
                                              >
                                                <img
                                                  src={require("../assets/images/chevron-right.png")}
                                                  height="16"
                                                  width="16"
                                                  className="img-fluid"
                                                />
                                                Start
                                              </Link>
                                            </OverlayTrigger>
                                          </>
                                        ) : (
                                          <>
                                            {list?.status === 2 && (
                                              <button
                                                // to="/"
                                                className="primary_btn"
                                                onClick={() =>
                                                  InfoHandler(list._id, 0)
                                                }
                                              >
                                                <img
                                                  src={require("../assets/images/chevron-right.png")}
                                                  height="16"
                                                  width="16"
                                                  className="img-fluid"
                                                />
                                                Info
                                              </button>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    {list.configType == 1 &&
                                      !list.templateId ? (
                                      <div className="incomplete-notify">
                                        <img
                                          src={require("../assets/images/warning.png")}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                        <p>Configuration Incomplete</p>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={require("../assets/images/no-meeting.png")}
                          style={{ height: "50%", width: "50%" }}
                        />
                      </div>
                      <h2 className="no-meeting">No Meeting Schedule</h2>
                    </>
                  )}
                </>
              )}

              {showContent2 && (
                <>
                  <div className="grid_dashbord">
                    <Row>
                      {MeetingDeatils.length > 0 ? (
                        <>
                          {MeetingDeatils.map((list) => (
                            <>
                              <Col lg="6" xl="4" md="6">
                                <div className="dashbord-right-body_1">
                                  <div className="dashbordrightbody-header dashbordrightbody-header1">
                                    <p>{list?.title}</p>
                                    <div>
                                      <ul>
                                        <li>
                                          <a
                                            href="#"
                                          // onClick={() => deleteMeeting(list._id)}
                                          >
                                            Count:{list.AttendeeUser?.[0]?.TotalUserCount ? list.AttendeeUser?.[0]?.TotalUserCount : 0}
                                          </a>
                                        </li>
                                        {list?.status != 2 ? (
                                          <li>
                                            <a
                                              href={
                                                list.configType == 0
                                                  ? `/express-edit/${list._id}`
                                                  : `/configSettings/${list._id}`
                                              }
                                            >
                                              Edit
                                            </a>
                                          </li>
                                        ) : (
                                          ""
                                        )}

                                        <li>
                                          <a
                                            href="#"
                                            onClick={() =>
                                              deleteMeeting(list._id)
                                            }
                                          >
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  {list?.mettingType === 1 ||
                                    list?.mettingType === 2 ? (
                                    <>
                                      {list?.sessions?.map(function (
                                        item,
                                        index
                                      ) {
                                        let currentSessionDate = new Date(
                                          item?.convertedDate
                                        );
                                        let nextSessionDate = new Date(
                                          list?.sessions?.[
                                            index + 1
                                          ]?.convertedDate
                                        );
                                        return (
                                          <>
                                            <div className="meeting_grid_card">
                                              <div className="dashbord-right-body1">
                                                <div className="dashbord-right-body_head">
                                                  <img
                                                    src={require("../assets/images/instant_img.png")}
                                                    alt="img"
                                                    className="img-fluid"
                                                  />
                                                  <div className="dashbord-right-body_head_1">
                                                    <h4>
                                                      <p>
                                                        {convertTime(
                                                          item?.startDate,
                                                          item?.timeZone
                                                        )}
                                                      </p>
                                                      <p>
                                                        TimeZone:
                                                        {item.timeZone}
                                                      </p>
                                                    </h4>
                                                  </div>
                                                </div>
                                                <div></div>
                                              </div>

                                              <div className="share-flex">
                                                {
                                                  item?.status != 2 &&
                                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Share attendee link</Tooltip>}>
                                                    <div className="share_icon">
                                                      <FontAwesomeIcon
                                                        icon={faShareFromSquare}
                                                        onClick={() =>
                                                          handleShow1(item, list)
                                                        }
                                                      />
                                                    </div>
                                                  </OverlayTrigger>
                                                }

                                                {schStartMeeting(
                                                  currentSessionDate,
                                                  nextSessionDate,
                                                  index,
                                                  list?.sessions,
                                                  list?.sessions?.length
                                                ) && item?.status !== 2 ? (
                                                  <>
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Start meeting</Tooltip>}>
                                                      <Link
                                                        to="#"
                                                        className="primary_btn"
                                                        onClick={() =>
                                                          createMeeting(item)
                                                        }
                                                      >
                                                        <img
                                                          src={require("../assets/images/chevron-right.png")}
                                                          height="16"
                                                          width="16"
                                                          className="img-fluid"
                                                        />
                                                        Start
                                                      </Link>
                                                    </OverlayTrigger>
                                                  </>
                                                ) : (
                                                  <>
                                                    {item?.status === 2 && (
                                                      <button
                                                        className="primary_btn"
                                                        onClick={() =>
                                                          InfoHandler(list._id, 1)
                                                        }
                                                      >
                                                        <img
                                                          src={require("../assets/images/chevron-right.png")}
                                                          height="16"
                                                          width="16"
                                                          className="img-fluid"
                                                        />
                                                        Info
                                                      </button>
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}

                                      {list.sessions.length == 0 ||
                                        (list.configType == 1 &&
                                          !list.templateId) ? (
                                        <div className="inconplete_config">
                                          <div className="incomplete-notify">
                                            <img
                                              src={require("../assets/images/warning.png")}
                                              alt="img"
                                              className="img-fluid"
                                            />
                                            <p>Configuration Incomplete</p>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {/* 
                      <div className="dashbord-right-body_footer_1">
                        <div className="dashbord-right-body_footer">
                          <div className="share_icon">
                            <FontAwesomeIcon
                              icon={faShareFromSquare}
                              onClick={() => handleShow1(list)}
                            />
                          </div>
                          <div>
                            <ul>
                              <li>
                                <a href={`/express-edit/${list._id}`}>
                                  Edit
                                </a>
                              </li>

                              <li>
                                <a
                                  href="#"
                                  onClick={() =>
                                    deleteMeeting(list._id)
                                  }
                                >
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="dashbord-right-body1">
                          <div className="dashbord-right-body_head">
                            <img
                              src={require("../assets/images/dashbord_icon.png")}
                              alt="img"
                              className="img-fluid"
                            />
                            <div className="dashbord-right-body_head_1">
                              <h4>
                                {moment(list.startTime).format(
                                  "LLLL"
                                )}
                              </h4>
                            </div>
                          </div>
                          <div></div>
                        </div>

                        <div className="dashbord-right-body_footer_1">
                          <div className="dashbord-right-body_footer">
                            <div className="share_icon">
                              <FontAwesomeIcon
                                icon={faShareFromSquare}
                                onClick={() => handleShow1(list)}
                              />
                            </div>
                            <div>
                              <Link
                                to="#"
                                className="primary_btn "
                                // onClick={() => createMeeting(list._id)}
                              >
                                <img
                                  src={require("../assets/images/chevron-right.png")}
                                  height="16"
                                  width="16"
                                  className="img-fluid"
                                />{" "}
                                Start
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div> */}
                                    </>
                                  ) : (
                                    <>
                                      <div className="meeting_grid_card">
                                        <div className="dashbord-right-body1">
                                          <div className="dashbord-right-body_head">
                                            <img
                                              src={require("../assets/images/instant_img.png")}
                                              alt="img"
                                              className="img-fluid"
                                            />
                                            {/* <img
                                                src={require("../assets/images/schedule-img.png")}
                                                alt="img"
                                                className="img-fluid"
                                              />
                                              <img
                                                src={require("../assets/images/schedulemeeting-img.png")}
                                                alt="img"
                                                className="img-fluid"
                                              /> */}
                                            <div className="dashbord-right-body_head_1">
                                              <h4>
                                                {moment(list?.createdAt).format(
                                                  "LLLL"
                                                )}
                                              </h4>
                                            </div>
                                          </div>

                                          <div></div>

                                          {list.configType == 1 &&
                                            !list.templateId ? (
                                            <div className="incomplete-notify">
                                              <img
                                                src={require("../assets/images/warning.png")}
                                                alt="img"
                                                className="img-fluid"
                                              />
                                              <p>Configuration Incomplete</p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="share-flex">
                                          {list?.status === 0 ? (
                                            <>
                                              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Share attendee link</Tooltip>}>
                                                <div className="share_icon">
                                                  <FontAwesomeIcon
                                                    icon={faShareFromSquare}
                                                    onClick={() =>
                                                      handleShow1(list, list)
                                                    }
                                                  />
                                                </div>
                                              </OverlayTrigger>
                                              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Start meeting</Tooltip>}>
                                                <Link
                                                  to="#"
                                                  className="primary_btn"
                                                  onClick={() =>
                                                    createMeeting(list)
                                                  }
                                                >
                                                  <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                  />
                                                  Start
                                                </Link>
                                              </OverlayTrigger>
                                            </>
                                          ) : (
                                            <>
                                              {list?.status === 2 && (
                                                <button
                                                  to="#"
                                                  className="primary_btn"
                                                   onClick={() =>
                                                  InfoHandler(list._id, 0)
                                                }
                                                >
                                                  <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                  />
                                                  Info
                                                </button>
                                              )}
                                            </>
                                          )}
                                        </div>
                                        {/* {
                                          list.configType == 1 && (!list?.templateId) ? <div className="incomplete-notify">
                                            <img
                                              src={require("../assets/images/warning.png")}
                                              alt="img"
                                              className="img-fluid"
                                            />
                                            <p>Configuration Incomplete</p>
                                          </div> : ''
                                        } */}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Col>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={require("../assets/images/no-meeting.png")}
                              style={{ height: "100%", width: "50%" }}
                            />
                          </div>
                          <h2 className="no-meeting">No Meeting Schedule</h2>
                        </>
                      )}
                    </Row>
                  </div>
                </>
              )}




            </div>
            <Innerfooter />
            <div></div>
          </div>
        </div>
      </div>

      <div className="popup1">
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="model-head" closeButton>
            <Modal.Title className="model-title">
              Webinar configuration
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className={`model-white ${activeModel === "express" ? "active" : ""
                }`}
              onClick={() => handleModelClick("express")}
            >
              <div className="model-content">
                <img
                  src={require("../assets/images/model-img1.png")}
                  alt="img"
                  className="img-fluid"
                />
                <div>
                  <h6>Express configuration</h6>
                  <ul>
                    <li>Ready in less than 60 seconds</li>
                    <li>Simple & Quick</li>
                    <li>Based on smart defaults</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={`model-white ${activeModel === "full" ? "active" : ""
                }`}
              onClick={() => handleModelClick("full")}
            >
              <div className="model-content">
                <img
                  src={require("../assets/images/model-img2.png")}
                  alt="img"
                  className="img-fluid"
                />
                <div>
                  <h6>Full configuration</h6>
                  <ul>
                    <li>Takes about 10 minutes</li>
                    <li>Flexible and powerful</li>
                    <li>Based on your custom settings</li>
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer className="footer-model">
             
              <img
                src={nextImg1}
                alt="img"
                className="img-fluid"
                onClick={confighandleShow}
              />
            </Modal.Footer> */}
        </Modal>
      </div>

      <div className="popup1">
        <Modal
          size="lg"
          show={showconfig}
          onHide={confighandleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="model-head" closeButton>
            <Modal.Title className="model-title">
              Webinar configuration
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className={`model-white ${activeModel1 === "Instant Meeting" ? "active" : ""
                }`}
              onClick={() => handleModelClick1("Instant Meeting")}
            >
              <div className="model-content">
                <img
                  src={require("../assets/images/model-img3.png")}
                  alt="img"
                  className="img-fluid"
                />
                <div>
                  <h6>Instant Meeting</h6>
                  <ul>
                    <li>
                      Both you and other participants are ready to live right
                      now
                    </li>
                    <li>
                      You will send the direct link to the live room to your
                      attendees
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={`model-white ${activeModel1 === "Schedule Meeting" ? "active" : ""
                }`}
              onClick={() => handleModelClick1("Schedule Meeting")}
            >
              <div className="model-content">
                <img
                  src={require("../assets/images/model-img4.png")}
                  alt="img"
                  className="img-fluid"
                />
                <div>
                  <h6>Schedule Meeting</h6>
                  <ul>
                    <li>
                      The webinar will take place at a later time in the future
                    </li>
                    <li>
                      Your attendees will fill in a registration form to receive
                      the webinar link
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div
              className={`model-white ${activeModel1 === "Schedule Recurring Meeting" ? "active" : ""
                }`}
              onClick={() => handleModelClick1("Schedule Recurring Meeting")}
            >
              <div className="model-content">
                <img
                  src={require("../assets/images/model-img5.png")}
                  alt="img"
                  className="img-fluid"
                />
                <div>
                  <h6>Schedule Recurring Meeting</h6>
                  <ul>
                    <li>
                      The room wil be reserved and always ready for you to log
                      in on demand
                    </li>
                    <li>
                      Your unique branded link to the live room will remain
                      constant
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </Modal.Body>
          {/* <Modal.Footer className="footer-model">
              <img src={nextImg} alt="img" className="img-fluid" />
              {activeModel1 != null ? (
                <img
                  src={nextImg1}
                  alt="img"
                  className="img-fluid"
                  onClick={confighandleShowonfig}
                />
              ) : (
                <img src={nextImg1} alt="img" className="img-fluid" />
              )}
            </Modal.Footer> */}
        </Modal>
      </div>

      <div className="popup1">
        <Modal
          size="lg"
          show={show1}
          onHide={handleClose1}
          keyboard={false}
          className="link_model"
        >
          <Modal.Header className="model-head" closeButton>
            <Modal.Title className="model-title">Your Link</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              defaultActiveKey="home"
              transition={false}
              id="noanim-tab-example"
              className="model_tab mb-3 mt-3"
            >
              <Tab
                eventKey="home"
                title={
                  <div
                    className={`dashboard_link_model ${activeBody === 1 ? "active" : ""
                      }`}
                    onClick={() => handleBodyClick(1)}
                  >
                    <div className="model_content_1">
                      <img
                        src={require("../assets/images/registration_imgs/link_img.png")}
                        alt="img"
                        className="img-fluid"
                      />
                      <h6>Attendees Link</h6>
                    </div>
                  </div>
                }
              >
                <Tabs
                  defaultActiveKey="home"
                  transition={false}
                  id="noanim-tab-example"
                  className="tab_button mb-3 mt-5"
                >
                  <Tab eventKey="home" title="Link to the registration page">
                    <p>
                      Send this link to your attendees so they may register for
                      your webinar
                    </p>
                    <div class="modal_form mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Registration page
                      </label>
                      <div className="modal_text_box">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleFormControlInput1"
                          disabled
                          value={`${config.front_URL}/*********/****`}
                        />
                        <div
                          className="add_info_img"
                          onClick={() => copyClipboard()}
                        >
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Copy attendee link</Tooltip>}>
                            <a
                              href="#"
                              onClick={() => toastAlert("success", "Link Copied")}
                            >
                              <img
                                src={require("../assets/images/copy2.png")}
                                className="img-fluid"
                              />
                            </a>
                          </OverlayTrigger>
                        </div>

                        {/* <a target="blank"  ref={`${config.front_URL}/attendeesRegister/${meetingId}`}> */}

                        {/* <a
                            target="blank"
                            href={`${config.front_URL}/attendeesRegister/${meetingId}`}
                          >
                            {" "}
                            <img
                              src={require("../assets/images/registration_imgs/arrow-right.png")}
                              className="img-fluid"
                            />
                          </a> */}
                        {/* </> */}
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="profile"
                    disabled={true}
                    title="Embed in your website"
                  >
                    <p>
                      Send this link to your attendees so they may register for
                      your webinar
                    </p>
                    <div class="modal_form mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Registration page
                      </label>
                      <div className="modal_text_box">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="https://:event.vaffix.com/register/1/ogw2kcw"
                        />
                        <div className="add_info_img">
                          <a href="#">
                            <img
                              src={require("../assets/images/registration_imgs/pencil.png")}
                              className="img-fluid"
                            />
                          </a>
                          <a href="#">
                            {" "}
                            <img
                              src={require("../assets/images/registration_imgs/arrow-right.png")}
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="directLink"
                    disabled={true}
                    title="Direct link to live room"
                  >
                    <p>
                      Send this link to your attendees so they may register for
                      your webinar
                    </p>
                    <div class="modal_form mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Registration page
                      </label>
                      <div className="modal_text_box">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="https://:event.vaffix.com/register/1/ogw2kcw"
                        />
                        <div className="add_info_img">
                          <a href="#">
                            <img
                              src={require("../assets/images/registration_imgs/pencil.png")}
                              className="img-fluid"
                            />
                          </a>
                          <a href="#">
                            {" "}
                            <img
                              src={require("../assets/images/registration_imgs/arrow-right.png")}
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </Tab>

              {/* <Tab
                  eventKey="profile"
                  title={
                    <div
                      className={`dashboard_link_model ${activeBody === 2 ? "active" : ""
                        }`}
                      onClick={() => handleBodyClick(2)}
                    >
                      <div className="model_content_1">
                        <img
                          src={require("../assets/images/registration_imgs/link_img_1.png")}
                          alt="img"
                          className="img-fluid"
                        />
                        <h6>Presenters</h6>
                      </div>
                    </div>
                  }
                >
                  <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="tab_button mb-3 mt-5"
                  >
                    <Tab eventKey="home" title="Link to the registration page">
                      <p>
                        Send this link to your attendees so they may register
                        for your webinar
                      </p>
                      <div class="modal_form mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Registration page
                        </label>
                        <div className="modal_text_box">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleFormControlInput1"
                            disabled
                            value={`${config.front_URL}/joinmetting/***`}
                          />
                          <div className="add_info_img">
                            <a href="#"><img
                            src={require("../assets/images/registration_imgs/pencil.png")}

                            className="img-fluid"
                          /></a>
                            <a
                              target="_blank"
                              href={`${meeting.moderator_URL}`}
                            >
                              {" "}
                              <img
                                src={require("../assets/images/registration_imgs/arrow-right.png")}
                                className="img-fluid"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="profile" title="Embed in your website">
                      <p>
                        Send this link to your attendees so they may register
                        for your webinar
                      </p>
                      <div class="modal_form mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Registration page
                        </label>
                        <div className="modal_text_box">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="https://:event.vaffix.com/register/1/ogw2kcw"
                          />
                          <div className="add_info_img">
                            <a href="#">
                              <img
                                src={require("../assets/images/registration_imgs/pencil.png")}
                                className="img-fluid"
                              />
                            </a>
                            <a href="#">
                              {" "}
                              <img
                                src={require("../assets/images/registration_imgs/arrow-right.png")}
                                className="img-fluid"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="directLink" title="Direct link to live room">
                      <p>
                        Send this link to your attendees so they may register
                        for your webinar
                      </p>
                      <div class="modal_form mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Registration page
                        </label>
                        <div className="modal_text_box">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="https://:event.vaffix.com/register/1/ogw2kcw"
                          />
                          <div className="add_info_img">
                            <a href="#">
                              <img
                                src={require("../assets/images/registration_imgs/pencil.png")}
                                className="img-fluid"
                              />
                            </a>
                            <a href="#">
                              {" "}
                              <img
                                src={require("../assets/images/registration_imgs/arrow-right.png")}
                                className="img-fluid"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Tab> */}

              {/* <Tab
                  eventKey="replay"
                  title={
                    <div
                      className={`dashboard_link_model ${activeBody === 3 ? "active" : ""
                        }`}
                      onClick={() => handleBodyClick(3)}
                    >
                      <div className="model_content_1">
                        <img
                          src={require("../assets/images/registration_imgs/link_img_2.png")}
                          alt="img"
                          className="img-fluid"
                        />

                        <h6>Replay</h6>
                      </div>
                    </div>
                  }
                > */}
              {/* <Tabs
              defaultActiveKey="home"
              transition={false}
              id="noanim-tab-example"
              className="tab_button mb-3 mt-5"
            >
              <Tab eventKey="home" title="Link to the registration page">
                <p>Send this link to your attendees so they may register for your webinar</p>
                <div class="modal_form mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Registration page</label>
                  <div className="modal_text_box">
                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="https://:event.vaffix.com/register/1/ogw2kcw" />
                    <div className="add_info_img">

                      <a href="#"><img
                        src={require("../assets/images/registration_imgs/pencil.png")}

                        className="img-fluid"
                      /></a>
                      <a href="#"> <img
                        src={require("../assets/images/registration_imgs/arrow-right.png")}
                        className="img-fluid"
                      /></a>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Embed in your website">
                <p>Send this link to your attendees so they may register for your webinar</p>
                <div class="modal_form mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Registration page</label>
                  <div className="modal_text_box">
                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="https://:event.vaffix.com/register/1/ogw2kcw" />
                    <div className="add_info_img">

                      <a href="#"><img
                        src={require("../assets/images/registration_imgs/pencil.png")}

                        className="img-fluid"
                      /></a>
                      <a href="#"> <img
                        src={require("../assets/images/registration_imgs/arrow-right.png")}
                        className="img-fluid"
                      /></a>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="directLink" title="Direct link to live room">
                <p>Send this link to your attendees so they may register for your webinar</p>
                <div class="modal_form mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Registration page</label>
                  <div className="modal_text_box">
                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="https://:event.vaffix.com/register/1/ogw2kcw" />
                    <div className="add_info_img">

                      <a href="#"><img
                        src={require("../assets/images/registration_imgs/pencil.png")}

                        className="img-fluid"
                      /></a>
                      <a href="#"> <img
                        src={require("../assets/images/registration_imgs/arrow-right.png")}
                        className="img-fluid"
                      /></a>
                    </div>
                  </div>
                </div>
              </Tab>

            </Tabs> */}
              {/* </Tab> */}
            </Tabs>
          </Modal.Body>
        </Modal>

        {/*Delete Confirmation modal*/}
        <ConfirmationModal
          showModal={showConfirmationModal}
          confirmModal={submitDelete}
          hideModal={hideConfirmationModal}
          config={confirmationConfig}
          id={delMeetingId}
          loader={loader}
        />
      </div>
    </>
  );
};
export default MyWebinar;
