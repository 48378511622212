import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Moment from "moment";
import Modal from "react-bootstrap/Modal";
import nextImg from "../assets/images/side-icon.svg";
import nextImg1 from "../assets/images/side-icon2.svg";
import Navinner from "../components/nav-inner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  Completed_meeting_Details,
  GetSingle_CompltedMeeting,
  getAll_Complted_meeting,
  getCompleted_Metting,
} from "../action/user.action";
import Innerfooter from "../components/inner-footer";
import Innerpagenavbar from "../components/innerpage-navbar";
import Nav from "react-bootstrap/Nav";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUsers,
  faChartPie,
  faFaceSmile,
  faClipboard,
  faArrowRightToBracket,
  faComment,
  faHand,
  faGrin,
  faHandsWash,
  faThumbsUp,
  faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import Footer from "../components/Footer";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import { isEmpty } from "../helper/helper";
import { toastAlert } from "../utils/toast";
import LiveChart from "./chart/live.chart";
import { Scrollbars } from "react-custom-scrollbars-2";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";

const columns = [
  {
    name: "Users",
    selector: (row) => row.Users,
    width: "240px",
    sortable: true,
  },
  {
    name: "Online Time",
    selector: (row) => row.online_time,
    width: "150px",
    sortable: true,
  },
  {
    name: "Talk Time",
    selector: (row) => row.Talk_time,
    width: "150px",
    sortable: true,
  },
  {
    name: "Webcam Time",
    selector: (row) => row.Webcam_time,
    width: "170px",
    sortable: true,
  },
  {
    name: "Message",
    selector: (row) => row.Message,
    width: "130px",
    sortable: true,
  },
  {
    name: "Emojis",
    selector: (row) => row.Emojis,
    sortable: true,
  },
  {
    name: "Raise Hands",
    selector: (row) => row.Raise_Hands,
    width: "150px",
    sortable: true,
  },
  {
    name: "Activity Score",
    selector: (row) => row.Activity_Score,
    width: "180px",
    sortable: true,
  },
  // {
  //   name: "Status",
  //   selector: (row) => row.Status,
  //   sortable: true,
  // },
];

const data = [
  {
    id: 1,
    Users: (
      <div className="users_tab">
        <div className="user_dp">
          <FontAwesomeIcon icon={faUser} />
        </div>
        <div>
          <h6>Gaayathri</h6>
          <p>
            <FontAwesomeIcon icon={faArrowRightToBracket} /> June 4,10:12:12 AM
          </p>
        </div>
      </div>
    ),
    online_time: (
      <div className="online_time">
        <div>
          <div className="mb-2 text-center">
            {" "}
            <img
              src={require("../assets/images/volume_img.png")}
              className="img-fluid"
            />{" "}
            00:12:23
          </div>
          <div>
            <ProgressBar now={60} />
          </div>
        </div>
      </div>
    ),
    Talk_time: (
      <div className="Talk_time">
        <p>00:12:23</p>
      </div>
    ),
    Webcam_time: (
      <div className="Webcam_time">
        <p>00:12:23</p>
      </div>
    ),
    Message: (
      <div className="Message">
        <FontAwesomeIcon icon={faComment} /> 1
      </div>
    ),
    Emojis: (
      <div className="Emojis">
        <FontAwesomeIcon icon={faFaceSmile} /> 1 Happy
      </div>
    ),
    Raise_Hands: (
      <div className="Raise_Hands">
        <FontAwesomeIcon icon={faHand} /> 1
      </div>
    ),
    Activity_Score: (
      <div>
        <div className="Activity_Score">
          <div className="active1"></div>
          <div className="active2"></div>
          <div className="active3"></div>
          <div className="active4"></div>
          <div></div>
          <div></div>
        </div>
      </div>
    ),
    Status: (
      <div className="status">
        {/* <button className="online_btn">Online</button> */}
        <button className="offline_btn">Offline</button>
      </div>
    ),
  },
];
const columns1 = [
  {
    name: "Users",
    selector: (row) => row.Users,
    width: "240px",
    sortable: true,
  },
  {
    name: "Online Time",
    selector: (row) => row.online_time,
    sortable: true,
  },
  {
    name: "Talk Time",
    selector: (row) => row.Talk_time,
    sortable: true,
  },
  {
    name: "Webcam Time",
    selector: (row) => row.Webcam_time,
    sortable: true,
  },
  {
    name: "Message",
    selector: (row) => row.Message,
    sortable: true,
  },
  {
    name: "Emojis",
    selector: (row) => row.Emojis,
    sortable: true,
  },
  {
    name: "Raise Hands",
    selector: (row) => row.Raise_Hands,
    sortable: true,
  },
  {
    name: "Activity Score",
    selector: (row) => row.Activity_Score,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.Status,
    sortable: true,
  },
];

const data1 = [
  {
    id: 1,
    Users: (
      <div className="users_tab">
        <div className="user_dp">
          <FontAwesomeIcon icon={faUser} />
        </div>
        <div>
          <h6>Gaayathri</h6>
          <p>
            <FontAwesomeIcon icon={faArrowRightToBracket} /> June 4,10:12:12 AM
          </p>
        </div>
      </div>
    ),
    online_time: (
      <div className="online_time">
        <div>
          <div className="mb-2 text-center">
            {" "}
            <img
              src={require("../assets/images/volume_img.png")}
              className="img-fluid"
            />{" "}
            00:12:23
          </div>
          <div>
            <ProgressBar now={60} />
          </div>
        </div>
      </div>
    ),
    Talk_time: (
      <div className="Talk_time">
        <p>00:12:23</p>
      </div>
    ),
    Webcam_time: (
      <div className="Webcam_time">
        <p>00:12:23</p>
      </div>
    ),
    Message: (
      <div className="Message">
        <FontAwesomeIcon icon={faComment} /> 1
      </div>
    ),
    Emojis: (
      <div className="Emojis">
        <FontAwesomeIcon icon={faFaceSmile} /> 1 Happy
      </div>
    ),
    Raise_Hands: (
      <div className="Raise_Hands">
        <FontAwesomeIcon icon={faHand} /> 1
      </div>
    ),
    Activity_Score: (
      <div>
        <div className="Activity_Score">
          <div className="active1"></div>
          <div className="active2"></div>
          <div className="active3"></div>
          <div className="active4"></div>
          <div></div>
          <div></div>
        </div>
      </div>
    ),
    Status: (
      <div className="status">
        {/* <button className="online_btn">Online</button> */}
        <button className="offline_btn">Offline</button>
      </div>
    ),
  },
];
const columns2 = [
  {
    name: "User",
    selector: (row) => row.User,
    sortable: true,
  },
  {
    name: "Total",
    selector: (row) => row.Total,
    sortable: true,
  },
  {
    name: "Question_1",
    selector: (row) => row.Question_1,
    sortable: true,
  },
];

const data2 = [
  {
    id: 1,
    User: (
      <div className="users_tab">
        <div className="user_dp">
          <FontAwesomeIcon icon={faUser} />
        </div>
        <h6>Gaayathri</h6>
      </div>
    ),
    Total: "1",

    Question_1: "True",
  },
];

const WebinarAnalytics = () => {
  const location = useLocation();
  console.log("analusss");

  console.log(location, "location");

  const [SessionDetaisl, setSessionDetaisl] = useState(
    location.state == undefined ? {} : location.state
  );
  const [analyticsData, setAnalyticsData] = useState("");
  const [userList, setUserList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(1);
  let emojis_config = {
    applause: "fas fa-hands-wash",
    happy: "far fa-grin",
    thumbsUp: "far fa-thumbs-up",
    raiseHand: "far fa-hand-paper",
    neutral: "far fa-meh",
    thumbsDown: "far fa-thumbs-down",
    sad: "far fa-frown",
  };

  useEffect(() => {
    if (analyticsData !== "") {
      console.log("analytics data=", analyticsData);
      analyticsData?.userList?.forEach(function (usrObject) {
        let userData = {
          id: 1,
          Users: (
            <div className="users_tab">
              <div className="user_dp">
                <FontAwesomeIcon icon={faUser} />
              </div>
              <div>
                <h6>{usrObject?.users?.name}</h6>
                <p>
                  <FontAwesomeIcon icon={faArrowRightToBracket} />{" "}
                  {usrObject?.users?.registerOn}
                </p>
              </div>
            </div>
          ),
          online_time: (
            <div className="online_time">
              <div>
                <div className="mb-2 text-center">
                  {" "}
                  <img
                    src={require("../assets/images/volume_img.png")}
                    className="img-fluid"
                  />{" "}
                  {usrObject?.onlineTime?.duration}
                </div>
                <div>
                  <ProgressBar now={usrObject?.onlineTime?.progress} />
                </div>
              </div>
            </div>
          ),
          Talk_time: (
            <div className="Talk_time">
              <p>{usrObject?.talkTime}</p>
            </div>
          ),
          Webcam_time: (
            <div className="Webcam_time">
              <p>{usrObject?.webcamTime}</p>
            </div>
          ),
          Message: (
            <div className="Message">
              <FontAwesomeIcon icon={faComment} /> {usrObject?.message?.count}
            </div>
          ),
          Emojis: (
            <>
              {usrObject?.emojis.map((obj) => {
                return (
                  <>
                    {obj.name !== "raiseHand" && (
                      <div className="Emojis">
                        <i
                          class={emojis_config[obj?.name]}
                          style={{ fontSize: "14px" }}
                        ></i>
                        {obj?.count}{" "}
                        {obj?.name.charAt(0).toUpperCase() + obj?.name.slice(1)}
                      </div>
                    )}
                  </>
                );
              })}
            </>
          ),
          Raise_Hands: (
            <>
              {usrObject?.raiseHands?.count > 0 && (
                <div className="Raise_Hands">
                  <FontAwesomeIcon icon={faHand} />{" "}
                  {usrObject?.raiseHands?.count}
                </div>
              )}
            </>
          ),
          Activity_Score: (
            <>
              {!usrObject?.activityScore?.isModerator ? (
                <div>
                  <div className="Activity_Score">
                    {usrObject?.activityScore?.score.map((item) => {
                      return <div className={`active${item}`}></div>;
                    })}
                  </div>
                </div>
              ) : (
                "N/A"
              )}
            </>
          ),
        };
        setUserList((prevArray) => [...prevArray, userData]);
      });
    }
  }, [analyticsData]);

  const getTimeLineCount = () => {
    return analyticsData?.timelineInfo?.reduce(function (a, b) {
      return a + b["count"];
    }, 0);
  };

  const [formValue, setFormValue] = useState({});
  const [webinarinfo, setWebinarInfo] = useState();
  const [initialState, setInitialState] = useState({});
  const [chartData, setChartData] = useState([]);
  const [xAxisData, setXaxisData] = useState([]);
  const [infoValues, setInfoValues] = useState({});

  var [learningDashboard, setlearningDashboard] = useState({});

  const [allMeeting, setAllmeeting] = useState([]);

  const [singleMeeting, setSingleMeeting] = useState([]);

  const getCompleted_Meetings = async () => {
    try {
      console.log(SessionDetaisl, "SessionId");

      if (isEmpty(SessionDetaisl)) {
        const { status, result } = await getAll_Complted_meeting();

        console.log(status, result, "sakthi ----- dashboard");
        if (status) {
          setAllmeeting(result.result);
        }
      } else {
        const { status, result } = await GetSingle_CompltedMeeting(
          SessionDetaisl
        );

        console.log(status, result, "sakthi-----dashboard");
        if (status) {
          setSingleMeeting(result.result);
          setFormValue({
            webinarId: result.result?.[0]._id,
            sessionId: result.result?.[0]?.webinar_Details?.[0]?._id,
          });
        }
      }

      // if(SessionId)
    } catch (error) {
      console.log(error);
    }
  };

  console.log(allMeeting, "allMeeting");
  useEffect(() => {
    getCompleted_Meetings();
  }, []);

  useEffect(() => {
    if (!isEmpty(singleMeeting)) {
      submithandler();
    }
  }, [singleMeeting]);

  console.log(initialState, "initialState");

  const changeHandler = async (e) => {
    setFormValue({});
    setWebinarInfo();
    console.log(e.target.value, "e.value");
    setFormValue({ webinarId: e.target.value });
    const data = allMeeting.find((val) => val._id == e.target.value);
    setSelectedOption(1);
    console.log(data, "data");
    setWebinarInfo(data);
  };

  console.log(singleMeeting, "webinarinfo");

  const getLearningDashBorad = async (data) => {
    console.log(data, "data----11111");

    for (let i = 0; i < data.tabledata.length; i++) {
      let userData = {
        id: 1,
        Users: (
          <div className="users_tab">
            <div className="user_dp">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div>
              <h6>{"Test user"}</h6>
              <p>
                {/* <FontAwesomeIcon icon={faArrowRightToBracket} /> {registerOn} */}
              </p>
            </div>
          </div>
        ),
        online_time: (
          <div className="online_time">
            <div>
              <div className="mb-2 text-center">
                {" "}
                <img
                  src={require("../assets/images/volume_img.png")}
                  className="img-fluid"
                />{" "}
                {data?.tabledata?.[i]?.usrOnlineDuration}
              </div>
              <div>
                <ProgressBar now={data?.tabledata?.[i]?.OnlineProgress} />
              </div>
            </div>
          </div>
        ),
        Talk_time: (
          <div className="Talk_time">
            <p>{data?.tabledata?.[i]?.totalTalkTimeValue}</p>
          </div>
        ),
        Webcam_time: (
          <div className="Webcam_time">
            <p>{data?.tabledata?.[i]?.userWebcamTiming}</p>
          </div>
        ),
        Message: (
          <div className="Message">
            <FontAwesomeIcon icon={faComment} />{" "}
            {/* {specificUserData?.totalOfMessages} */}
          </div>
        ),
        Emojis: (
          <>
            {data?.tabledata?.[i]?.emojis_data.map((obj) => {
              return (
                <>
                  {/* {obj.name !== "raiseHand" && (
                      <div className="Emojis">
                        <i
                          class={emojis_config[obj?.name]}
                          style={{ fontSize: "14px" }}
                        ></i>
                        {obj?.count}{" "}
                        {obj?.name.charAt(0).toUpperCase() + obj?.name.slice(1)}
                      </div>
                    )} */}
                </>
              );
            })}
          </>
        ),
        Raise_Hands: (
          <>
            {data?.tabledata?.[i]?.raiseHand_data?.count > 0 && (
              <div className="Raise_Hands">
                <FontAwesomeIcon icon={faHand} />{" "}
                {data?.tabledata?.[i]?.raiseHand_data?.count}
              </div>
            )}
          </>
        ),
        Activity_Score: (
          <>
            {/* {!specificUserData?.isModerator ? (
                <div>
                  <div className="Activity_Score">
                    {totActivityScore.map((item) => {
                      return <div className={`active${item}`}></div>;
                    })}
                  </div>
                </div>
              ) : (
                "N/A"
              )} */}
          </>
        ),
        // Status: (
        //   <div className="status">
        //     {/* <button className="online_btn">Online</button> */}
        //     <button className="offline_btn">Offline</button>
        //   </div>
        // ),
      };
      // setUserList((prevArray) => [...prevArray, userData]);
    }
  };

  const submithandler = async () => {
    try {
      if (!isEmpty(formValue)) {
        console.log(
          formValue,
          initialState.meeting_id,
          "formValue.webinarId != initialState.meeting_id"
        );

        if (!formValue.sessionId) {
          toastAlert("error", "Please Select Session");
          return false;
        }
        // if (formValue.webinarId != initialState._id) {
        setXaxisData([]);
        setChartData([]);

        console.log(analyticsData, "ananalyticsData");
        console.log(chartData, xAxisData, "xAxisData 11111");
        const { status, result } = await getCompleted_Metting(formValue);
        console.log(status, result, "status, result");

        let data = result?.result?.addtionInfo?.LarningDashboard;
        console.log("data:====", data);
        setUserList([]);
        setAnalyticsData(data);

        if (status) {
          const newChartData = [];
          const newXAxisData = [];

          for (let i of result.dashboardDate.result) {
            newChartData.push(i.users);
            newXAxisData.push(i.intervalStart);
          }

          result.result.session_Length = result.dashboardDate.session_length;
          setChartData(newChartData);
          setXaxisData(newXAxisData);
          setInitialState(result.result);

          //const result111111=await getLearningDashBorad(result.result.addtionInfo.LarningDashboard)
          // setlearningDashboard(result.result.addtionInfo.LarningDashboard)
        }
        // }
      } else {
        toastAlert("error", "Select a Webinar");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectSession = async (e) => {
    console.log(e.target.value, "e.value");
    setSelectedOption(e.target.value);
    setFormValue({ ...formValue, sessionId: e.target.value });
  };

  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard">
          <Innerpagenavbar />
          <div className="dashbord-right mt-5">
            <div className="dashbord-right-body-1">
              <div className="dashbord-right-body">
                <div className="dashbordrightbody-header">
                  <p>My Analytics</p>
                </div>
                <div className="dashbordrightbody-body">
                  <div className="dashbordrightbody-list1 analytics">
                    <div className="analytics_select">
                      <div className="select_box">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Webinar
                        </label>

                        {singleMeeting.length > 0 ? (
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            // onChange={changeHandler}
                          >
                            <option selected>{singleMeeting[0].title}</option>

                            {/* <option value="2">Two</option>
                        <option value="3">Three</option> */}
                          </select>
                        ) : (
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={changeHandler}
                          >
                            <option selected>Open this select menu</option>
                            {allMeeting.length > 0 &&
                              allMeeting.map((val) => (
                                <option value={val._id}>{val.title}</option>
                              ))}
                            {/* <option value="2">Two</option>
                          <option value="3">Three</option> */}
                          </select>
                        )}
                        {/* <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={changeHandler}
                        >


                          <option selected>Open this select menu</option>
                          {allMeeting.length > 0 &&
                            allMeeting.map((val) => (
                              <option value={val._id}>
                                {val.title}
                              </option>
                            ))}
                          
                        </select> */}
                      </div>
                      <div className="select_box">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Session
                        </label>
                        {singleMeeting.length > 0 ? (
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            // onChange={selectSession}
                          >
                            <option>
                              {moment(
                                singleMeeting?.[0]?.webinar_Details?.[0]
                                  .startDate
                              ).format("LLLL")}
                            </option>
                          </select>
                        ) : (
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={selectSession}
                            value={selectedOption}
                            // value={}
                          >
                            <option value={1}>Select Session</option>
                            {webinarinfo?.webinar_Details.map((val) => (
                              <option value={val._id}>
                                {moment(val.startDate).format("LLLL")}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                      {/* <div className="select_box">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Event
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>All Event</option>
                          {webinarinfo?.webinar_Details.map(
                            (val) => (
                              <option value="2">All Event</option>
                            )
                          )}
                          <option value="3">Three</option>
                        </select>
                      </div> */}

                      {singleMeeting.length > 0 ? (
                        ""
                      ) : (
                        <Link
                          to="#"
                          className="primary_btn"
                          onClick={submithandler}
                        >
                          <img
                            src={require("../assets/images/chevron-right.png")}
                            height="16"
                            width="16"
                            className="img-fluid"
                          />
                          Go
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {initialState._id ? (
                <>
                  <div className="dashbord-right-body mt-4">
                    <div className="dashbordrightbody-header">
                      <p>Traffic</p>
                    </div>
                    <div className="dashbordrightbody-body">
                      <div className="dashbordrightbody-list1 analytics">
                        <div className="traffic_box">
                          <div className="traffic_info">
                            <img
                              src={require("../assets/images/registration_imgs/traffic_img.png")}
                              className="img-fluid"
                            />
                            <p>Visitors</p>
                            <span>0</span>
                          </div>
                          <div className="traffic_info">
                            <img
                              src={require("../assets/images/registration_imgs/traffic_img_1.png")}
                              className="img-fluid"
                            />
                            <p>Register rate</p>
                            <span>
                              {initialState?.addtionInfo?.RegisterRate}%
                            </span>
                          </div>
                          <div className="traffic_info">
                            <img
                              src={require("../assets/images/registration_imgs/traffic_img_2.png")}
                              className="img-fluid"
                            />
                            <p>Registrants</p>
                            <span>
                              {
                                initialState?.Users?.filter(
                                  (val) => val.isModerator != true
                                ).length
                              }
                            </span>
                          </div>
                          <div className="traffic_info">
                            <img
                              src={require("../assets/images/registration_imgs/location.png")}
                              className="img-fluid"
                            />
                            <p>Live attendees</p>
                            <span>
                              {
                                initialState?.Users?.filter(
                                  (val) => val.isModerator != true
                                ).length
                              }
                            </span>
                          </div>
                          {/* <div className="traffic_info">
                      <img
                        src={require("../assets/images/registration_imgs/traffic_img_4.png")}
                        className="img-fluid"
                      />
                      <p>Live attendees</p>
                      <span>0</span>
                      <div className="traffic_info_1">
                        <img
                          src={require("../assets/images/registration_imgs/traffic_img_1.png")}
                          className="img-fluid"
                        />
                        <p>Register rate</p>
                        <span>0</span>
                      </div>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dashbord-right-body mt-4">
                    <div className="dashbordrightbody-header">
                      <p>Engagement</p>
                    </div>
                    <div className="dashbordrightbody-body">
                      <div className="dashbordrightbody-list1 live_webinar">
                        <Row>
                          <Col lg={6}>
                            <div className="live_content mt-3">
                              <h4>Live webinar</h4>
                              <LiveChart
                                xAxisData={xAxisData}
                                chartData={chartData}
                              />
                              {/* {config} */}
                              {/* <img
                          src={require("../assets/images/registration_imgs/engage_img.png")}
                          className="img-fluid"
                        /> */}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="webinar_2_info">
                              <div className="webinar_2_content">
                                <div className="live_webinar_2">
                                  <img
                                    src={require("../assets/images/registration_imgs/attendees.png")}
                                    className="img-fluid"
                                  />
                                  <p>Live attendees</p>
                                </div>
                                <p>
                                  {
                                    initialState?.Users?.filter(
                                      (val) => val.isModerator != true
                                    ).length
                                  }
                                </p>
                              </div>
                              <div className="webinar_2_content">
                                <div className="live_webinar_2">
                                  <img
                                    src={require("../assets/images/registration_imgs/clock.png")}
                                    className="img-fluid"
                                  />
                                  <p>Session length</p>
                                </div>
                                <p>
                                  {initialState?.addtionInfo?.session_length}
                                </p>
                              </div>
                              <div className="webinar_2_content">
                                <div className="live_webinar_2">
                                  <img
                                    src={require("../assets/images/registration_imgs/time.png")}
                                    className="img-fluid"
                                  />
                                  <p>Avrg. time on live room</p>
                                </div>
                                <p>00:00:00</p>
                              </div>
                              <div className="webinar_2_content">
                                <div className="live_webinar_2">
                                  <img
                                    src={require("../assets/images/registration_imgs/eye.png")}
                                    className="img-fluid"
                                  />
                                  <p>Watched the full webinar</p>
                                </div>
                                <p>0</p>
                              </div>
                              <div className="webinar_2_content">
                                <div className="live_webinar_2">
                                  <img
                                    src={require("../assets/images/registration_imgs/thumsup.png")}
                                    className="img-fluid"
                                  />
                                  <p>User rating (0)</p>
                                </div>
                                <p>
                                  {" "}
                                  <img
                                    src={require("../assets/images/registration_imgs/star_img.png")}
                                    className="img-fluid"
                                  />
                                </p>
                              </div>
                            </div>
                          </Col>
                          {/* <Col lg={6}>
                              <div className="live_content mt-3">
                                <h4>Replay webinar</h4>
                                <img
                                  src={require("../assets/images/registration_imgs/engage_img.png")}
                                  className="img-fluid"
                                />
                              </div>
                            </Col> */}
                        </Row>

                        <Row>
                          {/* <Col lg={6}>
                              <div className="webinar_2_info">
                                <div className="webinar_2_content">
                                  <div className="live_webinar_2">
                                    <img
                                      src={require("../assets/images/registration_imgs/attendees.png")}
                                      className="img-fluid"
                                    />
                                    <p>Live attendees</p>
                                  </div>
                                  <p>0</p>
                                </div>
                                <div className="webinar_2_content">
                                  <div className="live_webinar_2">
                                    <img
                                      src={require("../assets/images/registration_imgs/clock.png")}
                                      className="img-fluid"
                                    />
                                    <p>Session length</p>
                                  </div>
                                  <p>00:00:00</p>
                                </div>
                                <div className="webinar_2_content">
                                  <div className="live_webinar_2">
                                    <img
                                      src={require("../assets/images/registration_imgs/time.png")}
                                      className="img-fluid"
                                    />
                                    <p>Avrg. time on live room</p>
                                  </div>
                                  <p>00:00:00</p>
                                </div>
                              </div>
                            </Col> */}
                        </Row>
                      </div>
                    </div>
                  </div>
                  {/* <div className="dashbord-right-body mt-4">
              <div className="dashbordrightbody-header">
                <p>Monetization</p>
              </div>
              <div className="dashbordrightbody-body">
                <div className="dashbordrightbody-list1 analytics">
                  <Row>
                    <Col lg={4}>
                      <div className="monetization_box ">
                        <h4>Registration fees</h4>
                        <div className="monetization_box_content monetization_main_box mt-4">
                          <img
                            src={require("../assets/images/registration_imgs/monetization_img.png")}
                            className="img-fluid"
                          />
                          <div className="monetization_head">
                            <h6>0 </h6>
                            <p>Number of tickets</p>
                          </div>
                        </div>

                        <div className="monetization_box_content monetization_main_box mt-4">
                          <img
                            src={require("../assets/images/registration_imgs/monetization_img_1.png")}
                            className="img-fluid"
                          />
                          <div className="monetization_head">
                            <h6>
                              0 <span>USD</span>
                            </h6>
                            <p>Number of tickets</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <div className="monetization_box monetization_box_1">
                        <div className="monetization_box_2">
                          <Tabs
                            defaultActiveKey="home"
                            transition={false}
                            id="noanim-tab-example"
                            className="tab_button mb-3  "
                          >
                            <Tab eventKey="home" title="Live Room">
                              <div className="monetization_box_content mt-4   ">
                                <div className="monetization_box_content_1">
                                  <img
                                    src={require("../assets/images/registration_imgs/basket.png")}
                                    className="img-fluid"
                                  />
                                  <div className="monetization_head">
                                    <h6>0 </h6>
                                    <p>Number of tickets</p>
                                  </div>
                                </div>
                                <div className="sales_main">
                                  <div className="sales_sec">
                                    <div className="sales_content">
                                      <img
                                        src={require("../assets/images/registration_imgs/sales_img.png")}
                                        className="img-fluid sales_img"
                                      />
                                      <p>Sales conversion</p>
                                    </div>
                                    <h4>0%</h4>
                                  </div>
                                  <div className="sales_sec">
                                    <div className="sales_content">
                                      <img
                                        src={require("../assets/images/registration_imgs/sales_img_1.png")}
                                        className="img-fluid sales_img"
                                      />
                                      <p>Sales conversion</p>
                                    </div>
                                    <h4>0%</h4>
                                  </div>
                                </div>
                              </div>
                              <div className="monetization_box_content mt-4">
                                <div className="monetization_box_content_1">
                                  <img
                                    src={require("../assets/images/registration_imgs/monetization_img_1.png")}
                                    className="img-fluid"
                                  />
                                  <div className="monetization_head">
                                    <h6>0 </h6>
                                    <p>Number of tickets</p>
                                  </div>
                                </div>
                                <div className="sales_main">
                                  <div className="sales_sec">
                                    <div className="sales_content">
                                      <img
                                        src={require("../assets/images/registration_imgs/sales_img.png")}
                                        className="img-fluid sales_img"
                                      />
                                      <p>Sales conversion</p>
                                    </div>
                                    <h4>0%</h4>
                                  </div>
                                  <div className="sales_sec">
                                    <div className="sales_content">
                                      <img
                                        src={require("../assets/images/registration_imgs/sales_img_1.png")}
                                        className="img-fluid sales_img"
                                      />
                                      <p>Sales conversion</p>
                                    </div>
                                    <h4>0%</h4>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                            <Tab eventKey="profile" title="Replay Room">
                              <div className="monetization_box_content mt-4   ">
                                <div className="monetization_box_content_1">
                                  <img
                                    src={require("../assets/images/registration_imgs/basket.png")}
                                    className="img-fluid"
                                  />
                                  <div className="monetization_head">
                                    <h6>0 </h6>
                                    <p>Number of tickets</p>
                                  </div>
                                </div>
                                <div className="sales_main">
                                  <div className="sales_sec">
                                    <div className="sales_content">
                                      <img
                                        src={require("../assets/images/registration_imgs/sales_img.png")}
                                        className="img-fluid sales_img"
                                      />
                                      <p>Sales conversion</p>
                                    </div>
                                    <h4>0%</h4>
                                  </div>
                                  <div className="sales_sec">
                                    <div className="sales_content">
                                      <img
                                        src={require("../assets/images/registration_imgs/sales_img_1.png")}
                                        className="img-fluid sales_img"
                                      />
                                      <p>Sales conversion</p>
                                    </div>
                                    <h4>0%</h4>
                                  </div>
                                </div>
                              </div>
                              <div className="monetization_box_content mt-4">
                                <div className="monetization_box_content_1">
                                  <img
                                    src={require("../assets/images/registration_imgs/monetization_img_1.png")}
                                    className="img-fluid"
                                  />
                                  <div className="monetization_head">
                                    <h6>0 </h6>
                                    <p>Number of tickets</p>
                                  </div>
                                </div>
                                <div className="sales_main">
                                  <div className="sales_sec">
                                    <div className="sales_content">
                                      <img
                                        src={require("../assets/images/registration_imgs/sales_img.png")}
                                        className="img-fluid sales_img"
                                      />
                                      <p>Sales conversion</p>
                                    </div>
                                    <h4>0%</h4>
                                  </div>
                                  <div className="sales_sec">
                                    <div className="sales_content">
                                      <img
                                        src={require("../assets/images/registration_imgs/sales_img_1.png")}
                                        className="img-fluid sales_img"
                                      />
                                      <p>Sales conversion</p>
                                    </div>
                                    <h4>0%</h4>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div> */}
                  {/* <div className="dashbord-right-body mt-5">
              <div className="dashbordrightbody-header">
                <p>Notification</p>
              </div>
              <div className="dashbordrightbody-body">
                <div className="dashbordrightbody-list1 registration_alert analytics">
                  <div className="pre_notification">
                    <ul className="pre_notification_list">
                      <li className="list_msg">
                        <h4 className="mb-2">Pre-Webinar notifications</h4>
                        <p>
                          Keep them in the loop of the webinar they've
                          registered for, It's a great opportunity to hand them
                          their link to the webinar room, and to remind them of
                          the topics that will be covered. It helps greatly to
                          maximize attendance!
                        </p>
                        <ul className="pre_notification_1 mt-3">
                          <li>
                            <div className="confirm_msg">
                              <div className="add_info">
                                <h4>Register Confirmation Message</h4>
                                <div className="add_info_span">
                                  <a href="#">Add Phone</a>
                                  <a href="#">Add Email</a>
                                </div>
                              </div>
                              <div className="confirm_msg_content mt-4">
                                <div className="add_info_content">
                                  <img
                                    src={require("../assets/images/registration_imgs/msg.png")}
                                    className="img-fluid"
                                  />
                                  <div>
                                    <h4>Immediately</h4>
                                    <p>[Confirmation] Your webinar link</p>
                                  </div>
                                </div>
                                <div className="add_content_1">
                                  <div className="add_sub_content">
                                    <h4>0</h4>
                                    <p>Sent</p>
                                  </div>
                                  <div className="add_sub_content">
                                    <h4>0</h4>
                                    <p>Open</p>
                                  </div>
                                  <div className="add_sub_content">
                                    <h4>0</h4>
                                    <p>Click</p>
                                  </div>
                                </div>

                                <div className="add_info_img">
                                  <a href="#">
                                    {" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/swap.png")}
                                      className="img-fluid"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="confirm_msg">
                              <div className="add_info">
                                <h4>Pre-Webinar remainders</h4>
                                <div className="add_info_span">
                                  <a href="#">Add Phone</a>
                                  <a href="#">Add Email</a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="confirm_msg">
                              <div className="add_info">
                                <h4>Last minute notification</h4>
                                <div className="add_info_span">
                                  <a href="#">Add Phone</a>
                                  <a href="#">Add Email</a>
                                </div>
                              </div>

                              <div className="confirm_msg_content mt-4">
                                <div className="add_info_content">
                                  <img
                                    src={require("../assets/images/registration_imgs/msg.png")}
                                    className="img-fluid"
                                  />
                                  <div>
                                    <h4>Immediately</h4>
                                    <p>[Confirmation] Your webinar link</p>
                                  </div>
                                </div>
                                <div className="add_content_1">
                                  <div className="add_sub_content">
                                    <h4>0</h4>
                                    <p>Sent</p>
                                  </div>
                                  <div className="add_sub_content">
                                    <h4>0</h4>
                                    <p>Open</p>
                                  </div>
                                  <div className="add_sub_content">
                                    <h4>0</h4>
                                    <p>Click</p>
                                  </div>
                                </div>

                                <div className="add_info_img">
                                  <a href="#">
                                    {" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/swap.png")}
                                      className="img-fluid"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="list_msg">
                        <h4>Post Webinar notifications</h4>
                        <p>
                          Follow up with them to hand them the link to the
                          webinar replay in case they missed it, or remind them
                          of your product and sevices. this is also great
                          opportunity to invite them to another webinar event!
                        </p>
                        <ul className="pre_notification_1">
                          <li>
                            <div className="confirm_msg">
                              <div className="add_info">
                                <h4>Post Webinar follow ups</h4>
                                <div className="add_info_span">
                                  <a href="#">Add Phone</a>
                                  <a href="#">Add Email</a>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
                  {/* </> : <></>
            }
 */}
                </>
              ) : (
                <></>
              )}

              {analyticsData != "" && (
                <div className="dashbord-right-body dashbord-right-body_Analytics1 mt-5">
                  <div className="dashbordrightbody-header">
                    <p>Learning Analytics Dashboard</p>
                    <div className="Duration">
                      <p>{analyticsData?.meetingInfo?.startTime}</p>

                      <p>Duration : {analyticsData?.meetingInfo?.duration}</p>
                    </div>
                  </div>
                  <div className="dashbordrightbody-body">
                    {/* <p className="user_name">Username</p> */}
                    <div className="learning_analytics">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="first"
                      >
                        <Nav variant="pills nav-fill">
                          <Nav.Item>
                            <Nav.Link eventKey="first">
                              <div className="analytics_tab_head">
                                <div className="analytics_tab_content_1">
                                  <p>
                                    {
                                      analyticsData?.meetingInfo
                                        ?.activeUsersCount
                                    }
                                  </p>
                                  <h6>Active Users</h6>
                                </div>
                                <div className="analytics_tab_content_2">
                                  <FontAwesomeIcon icon={faUsers} />
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">
                              {" "}
                              <div className="analytics_tab_head">
                                <div className="analytics_tab_content_1">
                                  <p>
                                    {
                                      analyticsData?.meetingInfo
                                        ?.totalActivityScore
                                    }
                                  </p>
                                  <h6>Activity Score</h6>
                                </div>
                                <div className="analytics_tab_content_2">
                                  <FontAwesomeIcon icon={faChartPie} />
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">
                              {" "}
                              <div className="analytics_tab_head">
                                <div className="analytics_tab_content_1">
                                  <p>{getTimeLineCount()}</p>
                                  <h6>Time Line</h6>
                                </div>
                                <div className="analytics_tab_content_2">
                                  <FontAwesomeIcon icon={faFaceSmile} />
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="forth">
                              {" "}
                              <div className="analytics_tab_head">
                                <div className="analytics_tab_content_1">
                                  <p>
                                    {analyticsData?.meetingInfo?.pollsCount}
                                  </p>
                                  <h6>Polls</h6>
                                </div>
                                <div className="analytics_tab_content_2">
                                  <FontAwesomeIcon icon={faClipboard} />
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <div className="overview_text">
                          <h2>Overview</h2>
                        </div>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <DataTable columns={columns} data={userList} />
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <DataTable columns={columns1} data={userList} />
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <div className="tab_3_content">
                              <Scrollbars
                                style={{ minHeight: 250, width: "100%" }}
                                className="timelineScroll"
                              >
                                {analyticsData?.timelineInfo?.map(
                                  (item, index) => {
                                    return (
                                      <div className="timeline-item">
                                        <div className="users_tab1">
                                          <div className="userFixed">
                                            <div className="user_dp1">
                                              <FontAwesomeIcon icon={faUser} />
                                            </div>
                                            <div>
                                              <h6>
                                                {item?.userName
                                                  ?.substring(0, 8)
                                                  .concat("..")}
                                              </h6>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="line1"
                                          style={{
                                            height: 12,
                                            width: 500,
                                            marginLeft: 130,
                                          }}
                                        >
                                          {item?.emojis?.map((obj, index) => (
                                            <div
                                              className="likes_event"
                                              style={{ left: `${obj?.sentOn}` }}
                                            >
                                              <i
                                                class={
                                                  emojis_config[obj?.emojis]
                                                }
                                              ></i>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </Scrollbars>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="forth">
                            <div className="dashbord-right-body mt-5">
                              <div className="dashbordrightbody-body">
                                <div className="dashbordrightbody-list1 registration_alert analytics">
                                  <div className="pre_notification">
                                    <Accordion
                                      defaultActiveKey=""
                                      className="accMain Polls_acc"
                                      flush
                                    >
                                      {analyticsData?.pollsData?.map(
                                        (pollsObj, index) => {
                                          return (
                                            <Accordion.Item eventKey={index}>
                                              <Accordion.Header>
                                                <h5>{pollsObj?.userName}</h5>
                                                <div className="Total_count">
                                                  <h6>Total</h6>
                                                  <h6>
                                                    {pollsObj?.polls?.length}
                                                  </h6>
                                                </div>
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                {pollsObj?.polls?.map((obj) => (
                                                  <div className="confirm_msg_content">
                                                    <div className="add_info_content">
                                                      <p>{obj?.question}</p>
                                                    </div>
                                                    <div className="add_content_1">
                                                      <div className="add_sub_content">
                                                        <h4>{obj?.answers}</h4>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))}
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          );
                                        }
                                      )}
                                    </Accordion>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WebinarAnalytics;
