import React, { useEffect, useRef, useState } from "react";
// import Innerpagenavbar from "../components/innerpage-navbar";
import Form from "react-bootstrap/Form";

import { Accordion, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Basic_validation, persenter_validation } from "../../lib/validation";
import { decryptString, encryptString, isEmpty } from "../../helper/helper";
import {
  Create_Webinar,
  create_Meeting,
  update_Webinar,
} from "../../action/user.action";
import { setWebinar } from "../../redux/features/webinar_details";
import { toastAlert } from "../../utils/toast";
import ReactLanguageSelect from "react-languages-select";
import config from "../../lib/config";
import Innerpagenavbar from "../../components/innerpage-navbar";
import Innerfooter from "../../components/inner-footer";
import PropagateLoader from "react-spinners/PropagateLoader";
const initalForm = {
  Webinar_title: "",
  Webinar_language: "",
  Webinar_description: "",
  Webinar_name: "",
  edit: false,
};


const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};

function BasicSettings() {
  const { id } = useParams();

  const fileRef = useRef();

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false)
  const webinarDetails = useSelector((state) => state.Webinar);
  const [showBasic_edit, setShow_Basicedit] = useState(false);
  const [previewUrl, setpreviewUrl] = useState("");
  const [decryptStr, setDecryptString] = useState();

  const [initialState, setInitialState] = useState(initalForm);
  const [edit_presenter, setEdit_Presenter] = useState(false);
  const [newPresenter, setnewPresenter] = useState({
    preview: "",
    profile_image: "",
    Presenters_name: "",
    Presenters_email: "",
    edit: false,
  });

  const { preview, profile_image, Presenters_name, Presenters_email } =
    newPresenter;

  const [values, setValues] = useState([]);
  const [error, setError] = useState();

  const decryptStringFun = async () => {
    const dep = await decryptString(id, true);

    setDecryptString(JSON.parse(dep));
  };
  useEffect(() => {
    setInitialState({
      ...initialState,
      Webinar_title: webinarDetails.Webinar_title,
      Webinar_description: webinarDetails.Webinar_description,
      Webinar_language: webinarDetails.Webinar_language,
      Webinar_name: webinarDetails.Webinar_name,
    });

    decryptStringFun();
  }, [webinarDetails]);

  const dispatch = useDispatch();

  const set_Basic_Edit_Show = () => {
    setShow_Basicedit(!showBasic_edit);
  };

  const Changes_Lang_Handler = (value) => {
    setInitialState({ ...initialState, Webinar_language: value });
  };

  const changesHandler = async (e) => {
    const { id, value } = e.target;
    setInitialState({ ...initialState, [id]: value });
  };

  const editPresenters = async (type, val, i) => {
    console.log(val, i, "val, i");
    if (type) {
      setEdit_Presenter(true);
      webinarDetails.Presenters.splice(i, 1);
      setnewPresenter({ ...val });
    } else {
      values.splice(i, 1);
      setpreviewUrl(val.preview);
      setnewPresenter({ ...val });
    }
  };

  const addClick = async () => {
    const dat = await persenter_validation(newPresenter);
    console.log(dat, newPresenter, "newPresenter");
    if (isEmpty(dat)) {
      if (edit_presenter) {
        newPresenter.edit = true;
      }
      setValues([...values, newPresenter]);
      setnewPresenter({
        preview: "",
        image: "",
        Presenters_name: "",
        Presenters_email: "",
        edit: false,
      });
      setpreviewUrl("");
      setError();
    } else {
      setError(dat);
    }
  };

  const submitFormOne = async (e) => {
    try {
      e.preventDefault();

      console.log("r1,", webinarDetails);
      const dat = await Basic_validation(initialState);

      if (showBasic_edit) {
        // let result = []

        // if (webinarDetails.Presenters.length > 0) {

        console.log(values, initialState, webinarDetails, "showBasic_edit");

        const dep = await decryptString(id, true);
        const payload = {
          _id: webinarDetails._id,
          presenters: [...values, ...webinarDetails.Presenters],
          ...initialState,
          ...JSON.parse(dep),
        };

        console.log(payload, "payload");
        const { status, result } = await update_Webinar(payload);

        console.log(status, result, "status, result");
        if (status) {
          dispatch(setWebinar(result.result));
          toastAlert("success", "Webinar Updated");

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        // }
      } else {
        if (isEmpty(dat) && values.length != 0) {
          const dep = await decryptString(id, true);

          const payload = {
            presenters: values,
            ...initialState,
            ...JSON.parse(dep),
          };
          const { status, result } = await Create_Webinar(payload);

          console.log(status, result, "status, result");
          if (status) {
            toastAlert("success", "Basic Setting Created");
            const tkn = encryptString(result.result._id, true);

            setTimeout(() => {
              window.location.reload();
            }, 1000);
            dispatch(setWebinar(result.result));
          }
        } else {
          if (values.length == 0) {
            toastAlert("error", "Presenter Required");
          }
          setError(dat);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleListChange(event) {
    const { id } = event.target;
    if (id == "image") {
      setEdit_Presenter(false);
      const { files } = event.target;
      setpreviewUrl(URL.createObjectURL(files[0]));
      setnewPresenter({
        ...newPresenter,
        [id]: files,
        preview: URL.createObjectURL(files[0]),
      });
    } else {
      setnewPresenter({ ...newPresenter, [id]: event.target.value });
    }
  }

  const nextHandler = async () => {
    const id = await encryptString(webinarDetails._id, true);
    navigate(`/schedules/${id}`);
  };

  console.log(decryptStr, "decryptStr");

  const FinishHandler = async () => {
    try {
      setLoader(true)
      const payload = {
        id: webinarDetails._id
      };
      const { status, result } = await create_Meeting(payload);
      console.log(status, result, "webinar");
      if (status) {
        setLoader(false)
        window.location.href = "/webinar";
      }
    } catch (error) { }
  };
  return (
    <>

      {
        loader && (
          <div className="loadBefore">
            <PropagateLoader
              color={"#0d2a35"}
              loading={loader}
              cssOverride={override}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )
      }
      <div className="dashboard-page">
        <div className="dashboard">
          <Innerpagenavbar />
          <div className="dashbord-right mt-5">
            <div className="registration_nav_sec">
              <ul className="registration_list">
                <li>
                  <div className="registration_nav_content">
                    <img
                      src={require("../../assets/images/registration_imgs/notepad.png")}
                      className="img-fluid"
                    />
                    <p>Configuration</p>
                    {webinarDetails?._id ? (
                      <img
                        src={require("../../assets/images/registration_imgs/checked.png")}
                        className="img-fluid"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </li>
                {decryptStr?.type != "Instant Meeting" ? (
                  <>
                    <li>
                      <div className="registration_nav_content">
                        <img
                          src={require("../../assets/images/registration_imgs/calendar-tick.png")}
                          className="img-fluid"
                        />
                        <p>Schedules</p>
                      </div>
                    </li>
                    <li>
                      <div className="registration_nav_content">
                        <img
                          src={require("../../assets/images/registration_imgs/driver.png")}
                          className="img-fluid"
                        />
                        <p>Registration</p>
                      </div>
                    </li>
                    <li>
                      <div className="registration_nav_content">
                        <img
                          src={require("../../assets/images/registration_imgs/notification_2.png")}
                          className="img-fluid"
                        />
                        <p>Notification</p>
                      </div>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul>
            </div>
            <Accordion className="accMain" defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0" className="accItem">
                <Accordion.Header>
                  <p>Basic settings</p>
                  <div className="accordion-hearder-content">
                    {webinarDetails._id && showBasic_edit == false ? (
                      <>
                        <Link
                          className="accordionedit-btn"
                          onClick={set_Basic_Edit_Show}
                        >
                          Edit
                        </Link>
                        <img
                          src={require("../../assets/images/accordion-config.png")}
                          className="img-fluid"
                        />{" "}
                        <p>Configured</p>
                        {decryptStr?.type != "Instant Meeting" ? (
                          <Link
                            className="primary_btn"
                            onClick={() => nextHandler()}
                          >
                            <img
                              src={require("../../assets/images/chevron-right.png")}
                              height="16"
                              width="16"
                              className="img-fluid"
                            />{" "}
                            Next
                          </Link>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>
                        <div>
                          <Link
                            to="#"
                            className="primary_btn primary_btn2 me-4"
                          >
                            <svg
                              width="8"
                              height="12"
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.5 1L6.5 6L1.5 11"
                                stroke="#001F2A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Cancel
                          </Link>
                          <Link
                            to="#"
                            className="primary_btn"
                            onClick={submitFormOne}
                          >
                            <img
                              src={require("../../assets/images/chevron-right.png")}
                              height="16"
                              width="16"
                              className="img-fluid"
                            />{" "}
                            Confirm
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="white-box webinar-setting-content">
                    <Row>
                      <Col lg="2">
                        {" "}
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          {" "}
                          <Form.Label>Webinar language</Form.Label>{" "}
                          <ReactLanguageSelect
                            names={"international"}
                            // languages={languages.map(lang => lang.code)}

                            searchable={true}
                            searchPlaceholder="Search for a language"
                            aria-label="Default select example"
                            id="Webinar_language"
                            onSelect={(val) => Changes_Lang_Handler(val)}
                            defaultLanguage={webinarDetails?.Webinar_language}
                          />
                          {error && error.Webinar_language && (
                            <span style={{ color: "red" }}>
                              {error.Webinar_language}
                            </span>
                          )}
                          {/* <Form.Select onClick={ChangesHandler} id="webinar_lang" aria-label="Default select example">
                          <option>English</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select> */}
                          <p>The interface will be translated for your users</p>
                        </Form.Group>
                      </Col>
                      <Col lg="3">
                        {" "}
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Webinar name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Example: Webinar for my free leads"
                            id="Webinar_name"
                            defaultValue={webinarDetails?.Webinar_name}
                            onChange={changesHandler}
                          />
                          {error && error.Webinar_name && (
                            <span style={{ color: "red" }}>
                              {error.Webinar_name}
                            </span>
                          )}

                          <p>
                            Private title. for your reference only. Max 70
                            characters.
                          </p>
                        </Form.Group>
                      </Col>
                      <Col lg="3">
                        {" "}
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Webinar title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Example: How to lose weight in 6 weeks"
                            id="Webinar_title"
                            defaultValue={webinarDetails?.Webinar_title}
                            onChange={changesHandler}
                          />
                          <p>Public title. Max 70 characters.</p>
                          {error && error.Webinar_title && (
                            <span style={{ color: "red" }}>
                              {error.Webinar_title}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg="4">
                        {" "}
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Webinar description</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Example: All the tips and tricks to efficient diets and workouts"
                            id="Webinar_description"
                            defaultValue={webinarDetails?.Webinar_description}
                            onChange={changesHandler}
                          />
                          <p>Public description. Max 150 characters.</p>
                          {error && error.Webinar_description && (
                            <span style={{ color: "red" }}>
                              {error.Webinar_description}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg="5">
                      <div className="webinar-setting-content1 webinar-width">
                        <p className="webinar-setting-content1-head">
                          Presenters
                        </p>
                        {webinarDetails?.Presenters?.length > 0
                          ? webinarDetails.Presenters.map((val, i) => (
                            <div>
                              <div className="profile-present">
                                {" "}
                                <img
                                  src={`${config.API_URL}/presenter/${val.profile_image}`}
                                  className="img-fluid"
                                />{" "}
                                <div className="profile-detail">
                                  <div>
                                    <div className="name-content">
                                      <p>{val.Presenters_name}</p>
                                      <div className="verify">
                                        {" "}
                                        <img
                                          src={require("../../assets/images/verify-img.png")}
                                          className="img-fluid"
                                        />{" "}
                                        <p>Verified</p>
                                      </div>
                                    </div>
                                    <p className="email-profile">
                                      {val.Presenters_email}
                                    </p>
                                  </div>{" "}
                                  <div className="profile-change">
                                    <img
                                      src={require("../../assets/images/setting-img.png")}
                                      className="img-fluid"
                                    />{" "}
                                    <img
                                      src={require("../../assets/images/voice-img.png")}
                                      className="img-fluid"
                                    />{" "}
                                    <Link
                                      className="profile-edit"
                                      onClick={() =>
                                        editPresenters(true, val, i)
                                      }
                                    >
                                      Edit
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                          : ""}

                        {values?.length > 0 && (
                          <>
                            <div className="white-box1 webinar-setting-sub">
                              {values.map((list, i) => (
                                <div>
                                  <div className="profile-present">
                                    {" "}
                                    {list.edit ? (
                                      <img
                                        src={`${config.API_URL}/presenter/${list.profile_image}`}
                                        className="img-fluid"
                                      />
                                    ) : (
                                      <img
                                        src={list.preview}
                                        className="img-fluid"
                                      />
                                    )}
                                    <div className="profile-detail">
                                      <div>
                                        <div className="name-content">
                                          <p>{list.Presenters_name}</p>
                                          <div className="verify">
                                            {" "}
                                            <img
                                              src={require("../../assets/images/verify-img.png")}
                                              className="img-fluid"
                                            />{" "}
                                            <p>Verified</p>
                                          </div>
                                        </div>
                                        <p className="email-profile">
                                          {list.Presenters_email}
                                        </p>
                                      </div>{" "}
                                      <div className="profile-change">
                                        <img
                                          src={require("../../assets/images/setting-img.png")}
                                          className="img-fluid"
                                        />{" "}
                                        <img
                                          src={require("../../assets/images/voice-img.png")}
                                          className="img-fluid"
                                        />{" "}
                                        <Link
                                          className="profile-edit"
                                          onClick={() =>
                                            editPresenters(false, list, i)
                                          }
                                        >
                                          Edit
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    </Col>
                    <Col lg="7">
                      {" "}
                      <div className="webinar-setting-content1 webinar-width1">
                        <div>
                          <p className="webinar-setting-content1-head">
                            New Presenters
                          </p>
                          <div className="white-box1 webinar-setting-sub">
                            <div className=" NewPresenters">
                              {" "}
                              <Form.Control
                                ref={fileRef}
                                id="image"
                                type="file"
                                className="img_upload"
                                accept=".png,.jpg,.jpeg,.webp"
                                // defaultValue={ }
                                onChange={handleListChange}
                              />
                              {/* <i class="bi bi-folder-plus upload"  style={{ fontSize: '30px' }}></i> */}
                              {edit_presenter == false ? (
                                <>
                                  {previewUrl != "" ? (
                                    <img
                                      src={previewUrl}
                                      onClick={() => fileRef.current.click()}
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src={require("../../assets/images/profile-img2.png")}
                                        onClick={() => fileRef.current.click()}
                                        className="img-fluid"
                                      />

                                      {/* {error && error.image && <span style={{ color: 'red' }}>{error.image}</span>} */}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {previewUrl != "" ? (
                                    <img
                                      src={previewUrl}
                                      onClick={() => fileRef.current.click()}
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src={`${config.API_URL}/presenter/${profile_image}`}
                                        onClick={() => fileRef.current.click()}
                                        className="img-fluid"
                                      />
                                    </>
                                  )}
                                </>
                              )}
                              {error && error.image && (
                                <span style={{ color: "red" }}>
                                  {error.image}
                                </span>
                              )}
                              <div className="mail-box">
                                <Row>
                                  <Col lg="5">
                                    {" "}
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the first name and last name"
                                      id="Presenters_name"
                                      value={
                                        Presenters_name ? Presenters_name : ""
                                      }
                                      onChange={handleListChange}
                                    />
                                    {error && error.Presenters_name && (
                                      <span style={{ color: "red" }}>
                                        {error.Presenters_name}
                                      </span>
                                    )}
                                  </Col>

                                  <Col lg="7">
                                    {" "}
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the email..."
                                      id="Presenters_email"
                                      value={
                                        Presenters_email ? Presenters_email : ""
                                      }
                                      onChange={handleListChange}
                                    />{" "}
                                    {error && error.Presenters_email && (
                                      <span style={{ color: "red" }}>
                                        {error.Presenters_email}
                                      </span>
                                    )}
                                  </Col>
                                </Row>
                                {(decryptStr?.type == "Instant Meeting" &&
                                  webinarDetails?.Presenters?.length == 1) ||
                                  (decryptStr?.type == "Instant Meeting" &&
                                    values?.length == 1) ? (
                                  <Link className="profile-edit">Add</Link>
                                ) : (
                                  <Link
                                    className="profile-edit"
                                    onClick={addClick}
                                  >
                                    Add
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {decryptStr?.type == "Instant Meeting" && webinarDetails?._id ? (
              <Link to="#" className="primary_btn" onClick={FinishHandler}>
                <img
                  src={require("../../assets/images/chevron-right.png")}
                  height="16"
                  width="16"
                  className="img-fluid"
                />
                Finished
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Innerfooter />
    </>
  );
}

export default BasicSettings;
