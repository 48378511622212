import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/images/logo.svg";
import { Register_User } from "../action/user.action";
import { toastAlert } from "../utils/toast";
import { useNavigate } from "react-router-dom";
import { ValidateUserName, ValidateEmail } from "../helper/validator";
const InitialFormValue = {
  email: "",
  password: "",
  conform_password: "",
  username: "",
};

function Register() {
  const [formValue, setFormValue] = useState(InitialFormValue);
  const [error, setError] = useState();
  const [showPwd, setShowPwd] = useState(false);

  const [showconfPwd, setShowconfPwd] = useState(false);

  const [passmessage, setpassmessage] = useState("");
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState("");
  const [loading, setloading] = useState(false);

  const navigate = useNavigate();

  const { email, password, conform_password, username } = formValue;

  const changesHadler = async (e) => {
    try {
      const { id, value } = e.target;
      // console.log(id, value)
      setFormValue({ ...formValue, [id]: value });
      if (id == "password") {
        handlePassword(value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Fromvalidation = async () => {
    try {
      var validateError = {};
      if (username.trim() == "") {
        validateError.username = "Name Required";
      } else if (!ValidateUserName(username.trim())) {
        validateError.username = "Username cannot contain numbers";
      }
      if (email.trim() == "") {
        validateError.email = "Email Required";
      } else if (!ValidateEmail(email)) {
        validateError.email = "Invalid Email Address";
      }
      if (password.trim() == "") {
        validateError.password = "Password Required";
      } else if (Object.keys(passmessage).length != 0) {
        validateError.password = passmessage;
      }
      if (conform_password.trim() == "") {
        validateError.conform_password = "Confirm Password Required";
      } else if (password != conform_password) {
        validateError.conform_password =
          "Password and Confirm Password Does not Match";
      }

      setError(validateError);
      return validateError;
    } catch (err) {
      //console.log(err);
    }
  };

  const submit_Handler = async (e) => {
    e.preventDefault();
    try {
      setloading(true);
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;
      if (errorsSize == 0) {
        setloading(true);
        const { status, result } = await Register_User(formValue);

        if (status) {
          toastAlert("success", result.message);
          await sessionStorage.setItem(
            "USER_AUTH_VERIFY_TOKEN_DATA",
            result.encryptToken
          );
          setTimeout(() => {
            navigate("/EmailVerify");
          }, 1500);
        } else {
          setError(result.message);
        }
      }
    } catch (error) {
      toastAlert("error", "Something went wrong,please try again later");
    } finally {
      setloading(false);
    }
  };

  const handlePassword = (passwordValue) => {
    const strengthChecks = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    var errormsg = {};
    if (!strengthChecks.length) {
      errormsg = "Password must contain at least 8 characters";
    } else if (!strengthChecks.hasUpperCase) {
      errormsg = "Password must contain at least one uppercase letter.";
    } else if (!strengthChecks.hasLowerCase) {
      errormsg = "Password must contain at least one lowercase letter";
    } else if (!strengthChecks.hasDigit) {
      errormsg = "Password must contain at least one numeric character";
    } else if (!strengthChecks.hasSpecialChar) {
      errormsg = "Password must contain at least one special character";
    }
    setpassmessage(errormsg);

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    let strength =
      verifiedList.length == 5
        ? "Strong"
        : verifiedList.length >= 2
        ? "Medium"
        : "Weak";

    // setPassword(passwordValue);
    setProgress(`${(verifiedList.length / 5) * 100}%`);
    setMessage(strength);

    // console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);
  };

  const getActiveColor = (type) => {
    if (type === "Strong") return "#8BC926";
    if (type === "Medium") return "#FEBD01";
    return "red";
  };

  return (
    <>
      <div className="nav_sec">
        <Header />
      </div>

      <section className="login_page">
        <Container>
          <form className="mb-5">
            {/* <img
            src={logo}
            className="img-fluid brand_logo mx-auto d-block"
            alt="logo"
          /> */}
            <div className="login_content mt-5 mb-5">
              <h4 className="text-center">Register</h4>
              <div class="mb-4 mt-4">
                <label for="exampleFormControlInput1" class="form-label">
                  User Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="username"
                  placeholder="Enter your User Name"
                  defaultValue={username}
                  onChange={changesHadler}
                />

                {error && error.username ? (
                  <span style={{ color: "red" }}>{error.username}</span>
                ) : (
                  ""
                )}
              </div>

              <div class="mb-4 mt-4">
                <label for="exampleFormControlInput1" class="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Enter your email"
                  defaultValue={email}
                  onChange={changesHadler}
                />

                {error && error.email ? (
                  <span style={{ color: "red" }}>{error.email}</span>
                ) : (
                  ""
                )}
              </div>

              <label for="exampleFormControlInput1" class="form-label">
                Password
              </label>
              <div>
                <div class="input-group mb-4">
                  <input
                    type={showPwd ? "text" : "password"}
                    class="form-control"
                    id="password"
                    placeholder="Enter your password"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    defaultValue={password}
                    onChange={changesHadler}
                  />
                  <>
                    <span
                      class="input-group-text"
                      id="basic-addon2"
                      style={{ cursor: "pointer" }}
                    >
                      {showPwd ? (
                        <i
                          className="fa-regular fa-eye-slash"
                          onClick={() => setShowPwd(false)}
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye"
                          onClick={() => setShowPwd(true)}
                        ></i>
                      )}
                    </span>
                  </>
                </div>
                {password.length !== 0 ? (
                  <>
                    <div className="progress-bg">
                      <div
                        className="progress"
                        style={{
                          marginTop: "-14px",
                          height: "6px",
                          width: progress,
                          backgroundColor: getActiveColor(message),
                        }}
                      ></div>
                    </div>
                    <p
                      className="message"
                      style={{ color: getActiveColor(message) }}
                    >
                      Your password is {message}
                    </p>
                  </>
                ) : null}
                {error && error.password ? (
                  <span style={{ color: "red" }}>{error.password}</span>
                ) : (
                  ""
                )}
              </div>

              <label for="exampleFormControlInput1" class="form-label">
                Confirm Password
              </label>
              <div class="input-group mb-3">
                <input
                  type={showconfPwd ? "text" : "password"}
                  class="form-control"
                  id="conform_password"
                  placeholder="Enter your password"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  defaultValue={conform_password}
                  onChange={changesHadler}
                />
                <>
                  {/* <span class="input-group-text" id="basic-addon2"> */}
                  {/* {
                  showconfPwd ?
                    <FontAwesomeIcon icon={faEye} className="eye_icon" onClick={() => setShowconfPwd(!showconfPwd)} />
                    :
                    <FontAwesomeIcon icon={faEyeSlash} className="eye_icon" onClick={() => setShowconfPwd(!showconfPwd)} />
                } */}

                  <span
                    class="input-group-text"
                    id="basic-addon2"
                    style={{ cursor: "pointer" }}
                  >
                    {showconfPwd ? (
                      <i
                        className="fa-regular fa-eye-slash"
                        onClick={() => setShowconfPwd(false)}
                      ></i>
                    ) : (
                      <i
                        className="fa-regular fa-eye"
                        onClick={() => setShowconfPwd(true)}
                      ></i>
                    )}
                  </span>

                  {/* </span> */}
                </>
              </div>
              {error && error.conform_password ? (
                <span style={{ color: "red" }}>{error.conform_password}</span>
              ) : (
                ""
              )}
              <button
                //type="button"
                type="submit"
                class="login_button mt-3"
                disabled={loading}
                onClick={submit_Handler}
              >
                {loading && (
                  <i
                    className="fa fa-spinner fa-spin"
                    aria-hidden="true"
                    id="circle"
                  ></i>
                )}
                Register
              </button>

              <p className="text-center mt-4">
                Already have an account? <a href="/login">Login here</a>
              </p>
              {/* <p className="text-center mt-4">Or sign in with</p> */}
              {/* <div className="facebook_button mt-4">
              <a href="#" target="_blank" className="icon_button">
                <span>
                  <img
                    src={require("../assets/images/facebook-icon.png")}
                    className="img-fluid"
                    alt="face"
                  />
                </span>{" "}
                Facebook
              </a>
            </div> */}
            </div>
          </form>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default Register;
