import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import logo from "../../assets/images/logo.svg";

import { useParams } from "react-router-dom";
import moment from "moment";
//API
import { AttendeePassword, extractToken } from "../../action/user.action";
import { toastAlert } from "../../utils/toast";
import { isEmpty } from "../../helper/helper";

const initialFormState = {
  password: ''
}

function UserLandingPage() {
  const { token } = useParams();

  let [initialForm, setinitialForm] = useState(initialFormState)

  const { password } = initialForm

  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    const Payload = {
      token: token,
    };
    extractTokenData(Payload);
  }, []);

  const extractTokenData = async (Payload) => {
    try {
      const { status, result } = await extractToken(Payload);
      console.log("result:=", result);
      setResponseData(result?.result);
    } catch (error) {
      console.log(error);
    }
  };

  const [showconfPwd, setShowconfPwd] = useState(false)

  useEffect(() => {
    console.log(responseData);
  }, [responseData]);

  // const passwordValidation = async (val) => {
  //   const error = {}

  //   if (isEmpty(val.password)) {
  //     error.password = 'Password Required'
  //   }

  // }

  const goMeeting = async (userLink) => {


    // let link = document.createElement('a');
    // link.href = userLink;
    // link.setAttribute('target', '_blank');
    // link.click();

    // const payload = {

    // }
    // const { status, result } = await checkExistsMeeting()

    if (responseData.hasPassword) {
      if (isEmpty(password)) {
        toastAlert('error', 'Password Required')
        return false
      }
    }

    initialForm.id = responseData._id
    initialForm.sessionid = responseData.sessionid
    initialForm.email = responseData.email

    const { status, result } = await AttendeePassword(initialForm)

    console.log(status, result, 'status, result')
    // return
    if (status) {
      window.open(result.AttendeeUrl, '_self').focus();
    } else {
      toastAlert('error', result.message)
    }
    //   else {
    //   window.open(userLink, '_self').focus();
    // }
  }

  console.log(responseData, 'responseData')

  return (
    <>
      {responseData != null && (
        <>
          <section className="login_page newuser">
            <Container>
              <a href="/">
                <img
                  src={logo}
                  className="img-fluid brand_logo mx-auto d-block"
                  alt="logo"
                />
              </a>

              <div className="login_content mt-5">
                <h4 className="text-center">{responseData?.sessionTitle}</h4>
                <InputGroup className="mb-3 mt-5">
                  <InputGroup.Text id="basic-addon1">
                    {" "}
                    <img
                      src={require("../../assets/images/user_img.png")}
                      className="img-fluid"
                    />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    disabled={true}
                    value={responseData?.User_name}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    {" "}
                    <img
                      src={require("../../assets/images/envelope_img.png")}
                      className="img-fluid"
                    />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="User email"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={responseData?.email}
                    disabled={true}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    {" "}
                    <img
                      src={require("../../assets/images/clock_img.png")}
                      className="img-fluid"
                    />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Session time"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={moment(responseData?.sessionDate).format("LLLL")}
                    disabled={true}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    {" "}
                    <img
                      src={require("../../assets/images/presenter_img.png")}
                      className="img-fluid"
                    />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Presenter name"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    disabled={true}
                    value={responseData?.presenterName}
                  />
                </InputGroup>

                {
                  responseData?.hasPassword &&


                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      {" "}
                      <img
                        src={require("../../assets/images/presenter_img.png")}
                        className="img-fluid"
                      />
                    </InputGroup.Text>
                    <Form.Control
                      type={showconfPwd ? "text" : "password"}
                      placeholder="Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      // disabled={}
                      value={password}
                      onChange={(e) => setinitialForm({ ...initialForm, password: e.target.value })}
                    />
                    {
                      showconfPwd ?
                        <i
                          className="fa-regular fa-eye"

                          style={{
                            marginTop: "13px",
                            marginRight: "15px"
                          }}

                          onClick={() => setShowconfPwd(false)}
                        ></i> :
                        <i
                          className="fa-regular fa-eye-slash"
                          style={{
                            marginTop: "13px",
                            marginRight: "15px"
                          }}
                          onClick={() => setShowconfPwd(true)}
                        ></i>
                    }
                  </InputGroup>
                }

                {/* {
                  responseData.
                } */}


                <button to="" className="second-btn" onClick={() => goMeeting(responseData?.attendeLink)}>
                  Start Session
                </button>


                {/* {responseData?.attendeLink != '' ? (
                  <>
                    {responseData?.meetingStatus === 0 ? (

                      <button to="" className="second-btn" onClick={() => goMeeting(responseData?.attendeLink)}>
                        Start Session
                      </button>
                    ) : responseData?.meetingStatus === 2 ? (
                      <>
                        <button to="" disabled className="second-btn">
                          Meeting Is Ended
                        </button>
                      </>
                    ) : null}
                  </>
                ) : (
                  <button to="" disabled className="second-btn">
                    Meeting Not started
                  </button>
                )} */}
              </div>
            </Container>
          </section>
        </>
      )}
    </>
  );
}

export default UserLandingPage;
