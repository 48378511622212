

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Verify_Email } from "../action/user.action";

const Email_Verification = () => {

    const navigate = useNavigate()
    const params = useParams()
    console.log(params)

    useEffect(() => {
        VerifyEmail()
    }, [params])
    const VerifyEmail = async () => {
        try {
            const { status, result } = await Verify_Email(params)
            console.log(status, result)
            if (status) {
                navigate('/login', { state: { result: result.result } })
            }
        } catch (error) {
            console.log(error)
        }
    }

}

export default Email_Verification