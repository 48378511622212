import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Form, Button, InputGroup, Col, Row, Image } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Navinner from "../components/nav-inner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import nextImg from "../assets/images/side-icon.svg";
import nextImg1 from "../assets/images/side-icon2.svg";

import Innerpagenavbar from "../components/innerpage-navbar";
import Settingpage from "../components/dashboard/settingpage";
import Webinarpage from "./express/schudule/webinar";
import {
  Create_Webinar,
  create_Meeting,
  delete_Webinar,
  searchMeetings,
  updateUserDeatails,
  updateYoutube_key,
  verifyOtp,
} from "../action/user.action";
import { useDispatch, useSelector } from "react-redux";
import { encryptString, isEmpty } from "../helper/helper";
import {
  Search_Details,
  setWebinar,
  Webinar_Details,
} from "../redux/features/webinar_details";

import moment, { now } from "moment";

import config from "../lib/config";

import Innerfooter from "../components/inner-footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faShareFromSquare,
  faMagnifyingGlass,
  faListUl,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import Emptymeeting from "../assets/images/Emptymeeting.png";

//COMPONENTS
import ConfirmationModal from "../components/common/modals/ConfirmationModal";
import { toastAlert } from "../utils/toast";
import PropagateLoader from "react-spinners/PropagateLoader";

import { formatInTimeZone } from "date-fns-tz";
import { getUserDetails } from "../redux/features/userdetails";

const initalform = {
  facebookkey: "",
  youtubekey: "",
  firstname: "",
  lastname: "",
  emailid: "",
  otp: "",
};

const InnerSettings = () => {
  const user_deatils = useSelector((state) => state.User);

  const dispatch = useDispatch();
  console.log(user_deatils, "user_deatils");

  const [initialValue, setintialValue] = useState(initalform);
  const [streamKey, setStreamKey] = useState({
    youtubekey: "",
    facebookkey: "",
  });
  const [isOTPSent, setIsOtpSent] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const [error, setError] = useState();

  const { youtubekey, firstname, lastname, emailid, facebookkey } =
    initialValue;

  useEffect(() => {
    if (user_deatils) {
      setintialValue({
        ...initialValue,
        facebookkey: user_deatils.FaceBook_key,
        youtubekey: user_deatils.YouTube_key,
        firstname: user_deatils.userName,
        lastname: user_deatils.lastName,
        emailid: user_deatils.email,
      });
    }
  }, []);

  const updateYoutube = async () => {
    try {
      if (isDisable) {
        return false;
      }

      console.log(streamKey, "streamKeystreamKeystreamKey");
      if (streamKey.youtubekey == "" && streamKey.facebookkey == "") {
        toastAlert("error", "Key Required");
        return false;
      }

      console.log(streamKey, "streamKey");
      if (
        user_deatils.YouTube_key == streamKey.youtubekey &&
        user_deatils.FaceBook_key == streamKey.facebookkey
      ) {
        return false;
      }

      setIsDisable(true);
      const { result, status } = await updateYoutube_key(streamKey);

      console.log(result, status, "result, status");
      if (status) {
        toastAlert("success", result.message);

        dispatch(getUserDetails());
        setintialValue({
          ...initialValue,
          facebookkey:
            streamKey.facebookkey == ""
              ? user_deatils.FaceBook_key
              : streamKey.facebookkey,
          youtubekey:
            streamKey.youtubekey == ""
              ? user_deatils.YouTube_key
              : streamKey.youtubekey,
        });
        setIsDisable(false);
      } else {
        toastAlert("error", result.message);
        setIsDisable(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const profileValidation = async () => {
    const error = {};

    console.log(initialValue, "initialValue");
    if (isEmpty(initialValue.emailid)) {
      error.emailid = "Email Required";
    }
    if (isEmpty(initialValue.firstname)) {
      error.firstname = "firstname Required";
    }
    if (isEmpty(initialValue.lastname)) {
      error.lastname = "lastname Required";
    }
    return error;
  };
  const updateUser = async () => {
    try {
      if (isDisable) {
        return false;
      }
      const error = await profileValidation();

      console.log(error, "error______11111");
      if (Object.keys(error).length !== 0) {
        setError(error);
        return false;
      }
      setIsDisable(true);
      if (isOTPSent) {
        const { result, status } = await verifyOtp(initialValue);
        console.log(result, status, "result, status");
        if (status) {
          toastAlert("success", result.message);
          isOTPSent(false);
          dispatch(getUserDetails());
          setIsDisable(false);
        } else {
          toastAlert("error", result.message);
          setIsDisable(false);
        }
      } else {
        const { result, status } = await updateUserDeatails(initialValue);
        console.log(result, status, "result, status");
        if (status) {
          if (result.isEmailSent) {
            setIsOtpSent(true);
            toastAlert("success", result.message);
            setIsDisable(false);
          } else {
            toastAlert("success", result.message);
            dispatch(getUserDetails());
            setIsDisable(false);
          }
        } else {
          toastAlert("error", result.message);
          setIsDisable(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsDisable(false);
    }
  };

  const streamchangeHadler = (e) => {
    const { id, value } = e.target;
    setStreamKey({ ...streamKey, [id]: value });
  };

  const changeHadler = (e) => {
    const { id, value } = e.target;
    setintialValue({ ...initialValue, [id]: value });
  };
  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard">
          <Innerpagenavbar />

          <div className="dashbord-right  mt-5 mb-5">
            <div className="dashbord-right-body-1 dashboard-padding">
              <div className="inner_home_shadow p-4">
                <div className="inner_home_shadow inner_settings_shadow inner_settings_alignment">
                  <div className="settings_display">
                    {youtubekey == "" ? (
                      <Row>
                        <Col lg="4" md="4" sm="4" className="p-0 m-auto ">
                          <div className="img_position_basic">
                            <Form.Control
                              id="image"
                              type="file"
                              className="img_upload"
                              accept=".png,.jpg,.jpeg,.webp"
                              // defaultValue={ }
                            />

                            <>
                              <img
                                src={require("../assets/images/profile-img2.png")}
                                className="img-fluid image-dp"
                                width={"120px"}
                                height={"120px"}
                              />
                              <img
                                src={require("../assets/images/edit.png")}
                                class=" img-fluid image_edit"
                              />
                            </>
                          </div>
                        </Col>

                        <Col lg="8" md="8" sm="8" className=" m-auto ">
                          <Row>
                            <Col lg="12" className="profile_info mb-3">
                              <Form.Label>YouTube Streaming Key</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter YouTube key"
                                id="youtubekey"
                                // value={youtubekey}
                                onChange={streamchangeHadler}
                              />
                            </Col>

                            <Link
                              to="#"
                              className="primary_btn"
                              onClick={updateYoutube}
                            >
                              <img
                                src={require("../assets/images/chevron-right.png")}
                                height="16"
                                width="16"
                                className="img-fluid me-2"
                              />{" "}
                              Update
                            </Link>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <div className="inner_profile_settings">
                        <div className="reset_code">
                          <img
                            src={require("../assets/images/u_tube.png")}
                            className="img-fluid"
                          />
                          <p>YouTube Streaming Key (Do not share)</p>
                          <img
                            src={require("../assets/images/icon_4.png")}
                            className="img-fluid"
                          />
                        </div>
                        <h5 className="mt-3">{youtubekey}</h5>
                        <button
                          onClick={() =>
                            setintialValue({
                              ...initialValue,
                              youtubekey: "",
                            })
                          }
                        >
                          Reset code
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="settings_display">
                    {facebookkey == "" ? (
                      <Row>
                        <Col lg="4" md="4" sm="4" className="p-0 m-auto ">
                          <div className="img_position_basic">
                            <Form.Control
                              id="image"
                              type="file"
                              className="img_upload"
                              accept=".png,.jpg,.jpeg,.webp"
                              // defaultValue={ }
                            />

                            <>
                              <img
                                src={require("../assets/images/profile-img2.png")}
                                className="img-fluid image-dp"
                                width={"120px"}
                                height={"120px"}
                              />
                              <img
                                src={require("../assets/images/edit.png")}
                                class=" img-fluid image_edit"
                              />
                            </>
                          </div>
                        </Col>

                        <Col lg="8" md="8" sm="8" className=" m-auto ">
                          <Row>
                            <Col lg="12" className="profile_info mb-3">
                              <Form.Label> Facebook streaming key</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Facebook key "
                                id="facebookkey"
                                // value={youtubekey}
                                onChange={streamchangeHadler}
                              />
                            </Col>

                            <Link
                              to="#"
                              className="primary_btn"
                              onClick={updateYoutube}
                            >
                              <img
                                src={require("../assets/images/chevron-right.png")}
                                height="16"
                                width="16"
                                className="img-fluid me-2"
                              />{" "}
                              Update
                            </Link>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <div className="inner_profile_settings">
                        <div className="reset_code">
                          <img
                            src={require("../assets/images/u_tube.png")}
                            className="img-fluid"
                          />
                          <p>
                            {" "}
                            FB-1666829050782784-0-AbzWsWjvkmj5Ixyz(Do not share)
                          </p>
                          <img
                            src={require("../assets/images/icon_4.png")}
                            className="img-fluid"
                          />
                        </div>
                        <h5 className="mt-3">{facebookkey}</h5>
                        <button
                          onClick={() =>
                            setintialValue({
                              ...initialValue,
                              facebookkey: "",
                            })
                          }
                        >
                          Reset code
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="inner_fields mt-4"></div>
                </div>

                <div className="inner_home_shadow inner_settings_shadow">
                  <div className="inner_settings_head">
                    <p>Dashboard</p>
                    <button to="#" className="primary_btn" onClick={updateUser}>
                      <img
                        src={require("../assets/images/chevron-right.png")}
                        height="16"
                        width="16"
                        className="img-fluid me-2"
                      />{" "}
                      Update
                    </button>
                  </div>
                  <div className="inner_fields mt-4 mb-3">
                    <div className="error_input">
                      <InputGroup className=" input-box-1 ">
                        <InputGroup.Text id="basic-addon3">
                          <img
                            src={require("../assets/images/person_img.png")}
                            className="img-fluid"
                          />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="First name"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          defaultValue={firstname}
                          id="firstname"
                          onChange={changeHadler}
                        />
                      </InputGroup>

                      <div className="error_msg ">
                        {error && error.firstname && (
                          <span style={{ color: "red" }}>
                            {error.firstname}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="error_input">
                      <InputGroup className=" input-box-1 ">
                        <InputGroup.Text id="basic-addon3">
                          <img
                            src={require("../assets/images/person_img.png")}
                            className="img-fluid"
                          />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="Last name"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          defaultValue={lastname}
                          id="lastname"
                          onChange={changeHadler}
                        />
                      </InputGroup>

                      <div className="error_msg ">
                        {error && error.lastname && (
                          <span style={{ color: "red" }}>{error.lastname}</span>
                        )}
                      </div>
                    </div>
                    <div className="error_input">
                      <InputGroup className=" input-box-1 ">
                        <InputGroup.Text id="basic-addon3">
                          <img
                            src={require("../assets/images/mail_img.png")}
                            className="img-fluid"
                          />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="Email address"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          defaultValue={emailid}
                          id="emailid"
                          onChange={changeHadler}
                        />
                      </InputGroup>
                      <div className="error_msg ">
                        {error && error.emailid && (
                          <span style={{ color: "red" }}>{error.emailid}</span>
                        )}
                      </div>
                    </div>
                    {isOTPSent && (
                      <InputGroup className=" input-box-1 ">
                        <InputGroup.Text id="basic-addon3">
                          <img
                            src={require("../assets/images/mail_img.png")}
                            className="img-fluid"
                          />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="OTP"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          // value={emailid}
                          id="otp"
                          onChange={changeHadler}
                        />
                      </InputGroup>
                    )}
                  </div>
                </div>

                {/* <div className="inner_home_shadow inner_settings_shadow">
                <div className="inner_settings_head">
                  <p>Sign In </p>
                </div>

                <div className="inner_settings_details mt-4">
                  <p>Sign-In Email</p>
                  <span>sat***@gmail.com</span>
                  <Link to="#" className="primary_btn">
                    Edit
                  </Link>
                </div>
                <div className="inner_settings_details mt-3">
                  <p>Sign-In Email</p>
                  <span>sat***@gmail.com</span>
                  <Link to="#" className="primary_btn">
                    Edit
                  </Link>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InnerSettings;
