import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Header from "../../components/Navbar";
import Footer from "../../components/Footer";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/images/logo.svg";
import {
  Register_User,
  Register_User_Webinar,
  getSingleMeeting,
  getSingle_Webinar,
  submitInstancePassword,
} from "../../action/user.action";
import { toastAlert } from "../../utils/toast";
import { useNavigate, useParams } from "react-router-dom";
import { decryptString, isEmpty } from "../../helper/helper";
import { Link } from "react-router-dom";
import config from "../../lib/config";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Alert, Button, Modal, Row, Col } from "react-bootstrap";
const InitialFormValue = {
  email: "",
  username: "",
  acknowledgement: false,
  Lastname: "",
  phone: "",
  password: "",
};
function UserRegister() {
  const { id } = useParams();

  console.log(id, "id");

  const [initialState, setinitialState] = useState({});

  // const [webinae_details, setWebinar_details] = useState()

  const getMeetingDetails = async () => {
    try {
      let meeting_id = await decryptString(id, true);

      console.log(meeting_id, "meeting_id");

      const payload = JSON.parse(meeting_id);

      // return
      // const payload = {
      //     id: meeting_id
      // }
      const { status, result } = await getSingle_Webinar(payload);

      console.log(status, result, "getSingle_Webinar");
      if (status) {
        setinitialState(result.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getMeetingDetails();
    }
  }, []);

  const [formValue, setFormValue] = useState(InitialFormValue);

  const [showconfPwd, setShowconfPwd] = useState(false);

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const { email, username, acknowledgement, Lastname, phone } = formValue;

  const changesHadler = async (e) => {
    try {
      const { id, value } = e.target;
      console.log(id, value);
      if (id == "acknowledgement") {
        setFormValue({ ...formValue, [id]: true });
      } else {
        setFormValue({ ...formValue, [id]: value });
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(formValue, "formValue");

  const validation = async (value) => {
    const emailRegex = /[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/;
    let error = {};
    if (isEmpty(value.username)) {
      error.username = "User Name Required";
    }
    if (isEmpty(value.email)) {
      error.email = "Email Required";
    } else if (!emailRegex.test(value.email)) {
      error.email = "Invalid Email";
    }
    if (!value.acknowledgement) {
      error.acknowledgement = "Acknowledgement Required";
    }

    if (
      initialState.masterPasword != "" &&
      isEmpty(value.password) &&
      isEmailSent
    ) {
      error.password = "Password Required";
    }

    if (initialState.Lastname && isEmpty(value.Lastname)) {
      error.Lastname = "Lastname Required";
    }
    if (initialState.phone && isEmpty(value.phone)) {
      error.phone = "Phone number Required";
    }
    return error;
  };

  const submit_Handler = async (e) => {
    try {
      e.preventDefault();

      console.log(formValue, "dat");
      const dat = await validation(formValue);
      console.log(dat, "dat");
      if (isEmpty(dat)) {
        setError({});
        let meeting_id = await decryptString(id, true);

        meeting_id = JSON.parse(meeting_id);
        formValue.id = initialState._id;
        formValue.type = meeting_id.type;
        if (meeting_id.type == 1) {
          formValue.sessionid = initialState.Session_id;
        }
        const { status, result } = await Register_User_Webinar(formValue);
        console.log(status, result, "Register_User_Webinar");

        if (status) {
          if (result.AttendeeUrl) {
            window.location.href = result.AttendeeUrl;
            toastAlert("success", result.message);
          } else {
            if (result.isEmailSent) {
              setIsEmailSent(result.isEmailSent);
            }
            toastAlert("success", result.message);

            handleShow();
          }
        } else {
          if (result.isEmailSent) {
            setIsEmailSent(result.isEmailSent);
            toastAlert("error", "Email Already Sent");
          } else {
            toastAlert("error", result.message);
          }
        }
      } else {
        setError(dat);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(initialState, "acknowledgement");

  const submitpassword = async () => {
    try {
      console.log(formValue, "sssssssssssss");

      const dat = await validation(formValue);

      if (isEmpty(dat)) {
        setError({});
        const { status, result } = await submitInstancePassword(formValue);
        console.log(status, result, "status, result");
        if (status) {
          if (result.AttendeeUrl) {
            window.location.href = result.AttendeeUrl;
            toastAlert("success", result.message);
          } else {
            toastAlert("success", result.message);
          }
        } else {
          toastAlert("error", result.message);
          setTimeout(() => {
            getMeetingDetails();
          }, 1000);
        }
      } else {
        setError(dat);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="instant-meeting  register_page">
        <Container>
          <div className="instant-meeting-head">
            <div className="mt-5 mb-5">
              <a href="/">
                <img
                  src={logo}
                  className="img-fluid brand_logo mx-auto d-block"
                  alt="logo"
                />
              </a>
            </div>
            <div className="white-box instant-meeting-box">
              {initialState.status == 2 ? (
                <Alert key={"primary"} className="p-2 alert_email">
                  <i className="me-2 fa fa-info-circle"></i>
                  This session has been completed. We've covered all the
                  necessary topics and addressed your questions. If you have any
                  further inquiries or need additional assistance, feel free to
                  reach out. Thank you for your time, and have a great day!
                  &nbsp;&nbsp;
                </Alert>
              ) : (
                ""
              )}
              <div className="white-box">
                {/* <div>
                  <img
                    src={
                      initialState?.presenterTempImage ||
                      initialState?.presenterTempImage != ""
                        ? `${config.API_URL}/presenter/${initialState?.presenterTempImage}`
                        : require("../../assets/images/profile-img2.png")
                    }
                    className="img-fluid profile-img-width"
                  />{" "}
                  <h6>{initialState?.Presenters?.[0].Presenters_name}</h6>
                </div>
                <div className="invite-temp">
                  <p>You have been invited to join</p>
                  <h6>{initialState?.title}</h6>
                  <p>{initialState?.description}</p>
                </div> */}

                <Row className="align-items-center">
                  <Col lg="3" md="3" sm="4" className="p-0">
                    {" "}
                    <div className="mx-auto d-block">
                      <img
                        src={
                          initialState?.presenterTempImage ||
                          initialState?.presenterTempImage != ""
                            ? `${config.API_URL}/presenter/${initialState?.presenterTempImage}`
                            : require("../../assets/images/profile-img2.png")
                        }
                        className="img-fluid profile-img-width mx-auto d-block"
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                  </Col>
                  <Col lg="9" md="9" sm="8" className="p-0">
                    <div className="invite-temp">
                      <p>You have been invited to join</p>
                      <h6>{initialState?.title}</h6>
                      <p>{initialState?.description}</p>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="invite_details_head">
                <div className="invite_details">
                  <div className="text-box">
                    <label for="exampleFormControlInput1" class="form-label">
                      User Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="error_input">
                      <input
                        type="text"
                        class="form-control"
                        id="username"
                        placeholder="Enter your username"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        defaultValue={username}
                        onChange={changesHadler}
                        disabled={initialState.status == 2}
                      />
                      <div className="error_msg ">
                        {error && error.username ? (
                          <span style={{ color: "red" }}>{error.username}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  {initialState.Lastname && (
                    <div className="text-box">
                      <label for="exampleFormControlInput1" class="form-label">
                        Last Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="error_input">
                        <input
                          type="text"
                          class="form-control"
                          id="Lastname"
                          placeholder="Enter your Lastname"
                          defaultValue={Lastname}
                          onChange={changesHadler}
                          disabled={initialState.status == 2}
                        />
                        <div className="error_msg ">
                          {error && error.Lastname ? (
                            <span style={{ color: "red" }}>
                              {error.Lastname}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="text-box">
                    <label for="exampleFormControlInput1" class="form-label">
                      Email address <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="error_input">
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Enter your email"
                        defaultValue={email}
                        onChange={changesHadler}
                        disabled={initialState.status == 2}
                      />
                      <div className="error_msg ">
                        {error && error.email ? (
                          <span style={{ color: "red" }}>{error.email}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  {initialState.phone && (
                    <div className="text-box">
                      <label for="exampleFormControlInput1" class="form-label">
                        Phone Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        id="phone"
                        placeholder="Enter your Phone Number"
                        defaultValue={phone}
                        onChange={changesHadler}
                        disabled={initialState.status == 2}
                      />

                      {error && error.phone ? (
                        <span style={{ color: "red" }}>{error.phone}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>

                {initialState.registrationFees != 0 && (
                  <div className="text-box">
                    <label for="exampleFormControlInput1" class="form-label">
                      Registraction Fees <span style={{ color: "red" }}>*</span>
                    </label>

                    <input
                      type="text"
                      class="form-control"
                      id="username"
                      placeholder="Enter your username"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      defaultValue={initialState.registrationFees}
                      disabled
                      // onChange={changesHadler}
                    />
                  </div>
                )}

                <div class="form-check formcheck_register">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="acknowledgement"
                    onChange={changesHadler}
                  />

                  <label class="form-check-label" for="acknowledgement">
                    <span style={{ color: "red" }}>*</span> I acknowledge that
                    this session may be recorded. This may include my voice and
                    video if enabled.
                  </label>
                  <br></br>
                  {error && error.acknowledgement ? (
                    <span style={{ color: "red" }}>
                      {error.acknowledgement}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {initialState.status == 2 ? (
                  <Link
                    to="#"
                    className="primary_btn"
                    // onClick={submit_Handler}
                  >
                    <img
                      src={require("../../assets/images/chevron-right.png")}
                      height="16"
                      width="16"
                      className="img-fluid"
                    />{" "}
                    Meeting Completed
                  </Link>
                ) : (
                  <>
                    {isEmailSent ? (
                      <>
                        <div className="text-box">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Password <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="inner-accMain">
                            <InputGroup className="mb-3 input-box-1 ">
                              <Form.Control
                                type={showconfPwd ? "text" : "password"}
                                // style={{border}}

                                class="form-control"
                                id="password"
                                placeholder="Enter your Password"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                // defaultValue={initialState.password}
                                // disabled
                                onChange={changesHadler}
                                disabled={initialState.status == 2}
                              />

                              <InputGroup.Text id="basic-addon2">
                                {" "}
                                {showconfPwd ? (
                                  <i
                                    className="fa-regular fa-eye"
                                    // style={{
                                    //   marginTop: "13px",
                                    //   marginRight: "15px"
                                    // }}

                                    onClick={() => setShowconfPwd(false)}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa-regular fa-eye-slash"
                                    // style={{
                                    //   marginTop: "13px",
                                    //   marginRight: "15px"
                                    // }}
                                    onClick={() => setShowconfPwd(true)}
                                  ></i>
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          {error && error.password ? (
                            <span style={{ color: "red" }}>
                              {error.password}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <Link
                          to="#"
                          className="primary_btn"
                          onClick={submitpassword}
                        >
                          <img
                            src={require("../../assets/images/chevron-right.png")}
                            height="16"
                            width="16"
                            className="img-fluid"
                          />{" "}
                          Submit Password
                        </Link>
                      </>
                    ) : (
                      <div className="instant-btn">
                        {initialState.configType == 0 ? (
                          <>
                            {initialState.sessionType == 0 ? (
                              <>
                                <Link
                                  to="#"
                                  className="primary_btn"
                                  onClick={submit_Handler}
                                >
                                  <img
                                    src={require("../../assets/images/chevron-right.png")}
                                    height="16"
                                    width="16"
                                    className="img-fluid"
                                  />{" "}
                                  Join Meeting
                                </Link>
                              </>
                            ) : (
                              <>
                                <Link
                                  to="#"
                                  className="primary_btn"
                                  onClick={submit_Handler}
                                >
                                  <img
                                    src={require("../../assets/images/chevron-right.png")}
                                    height="16"
                                    width="16"
                                    className="img-fluid"
                                  />{" "}
                                  Register
                                </Link>
                              </>
                            )}
                          </>
                        ) : (
                          <Link
                            to="#"
                            className="primary_btn"
                            onClick={submit_Handler}
                          >
                            <img
                              src={require("../../assets/images/chevron-right.png")}
                              height="16"
                              width="16"
                              className="img-fluid"
                            />{" "}
                            Register
                          </Link>
                        )}
                      </div>
                    )}
                  </>
                )}

                {/* } */}
                {/* <div className="instant-login">
                                    <Link>
                                        Already have an account?<span>Login</span>
                                    </Link>
                                </div> */}
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        className="finish_model register_model"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className=" instant-meeting-head">
            <div className=" instant-meeting-box completed_meeting">
              {/* <div className="white-box">
                            <div>

                            </div> */}
              <center></center>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <p>Webinar Session Has Completed</p> */}
                <img
                  src={require("../../assets/images/group.png")}
                  className="img-fluid"
                />{" "}
              </div>
              <div className="finishpage mt-3">
                <p>
                  You have successfully registered for the webinar. The link to
                  join the webinar has been sent to your registered email
                  address.
                </p>
              </div>
              {/* <center>
                <button className="primary_btn mt-3">Go To Webinar List</button>
              </center> */}
              {/* </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <div className="nav_sec">
                <Header />
            </div> */}

      {/* <Footer /> */}
    </>
  );
}

export default UserRegister;
