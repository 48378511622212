import React, { useEffect, useMemo, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link, useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Innerpagenavbar from "../../../components/innerpage-navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactLanguageSelect from "react-languages-select";
import "react-languages-select/css/react-languages-select.css";
import { decryptString, encryptString, isEmpty } from "../../../helper/helper";
import { Button } from "react-bootstrap";
import {
  Basic_validation,
  persenter_validation,
  schedule_Valid,
  sessionValid,
  session_recurringValid,
  session_singleValid,
} from "../../../lib/validation";
import {
  Create_Webinar,
  add_webinar_schedule,
  getWebinarDetails,
  update_Webinar,
} from "../../../action/user.action";
import { toastAlert } from "../../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import {
  Webinar_Details,
  getWebinar,
  setWebinar,
} from "../../../redux/features/webinar_details";

import Datetime from "react-datetime";

import "react-datetime/css/react-datetime.css";

import moment from "moment";

import config from "../../../lib/config";
import Innerfooter from "../../../components/inner-footer";

const initalForm = {
  Webinar_title: "",
  Webinar_language: "",
  Webinar_description: "",
  Webinar_name: "",
  edit: false,
};

const Webinarpage = () => {
  const { id } = useParams();

  const fileRef = useRef();

  const dispatch = useDispatch();

  const [activeBody, setActiveBody] = useState(null);

  const handleBodyClick = (bodyId) => {
    console.log(bodyId, "bodyIdbodyIdbodyId");
    setActiveBody(bodyId);
  };

  const [email, setEmail] = useState(false);

  const handleEmailShow = () => setEmail(true);

  const [showBasic_edit, setShow_Basicedit] = useState(false);

  const [edit_presenter, setEdit_Presenter] = useState(false);

  const [selected, setSelected] = useState("");

  const [previewUrl, setpreviewUrl] = useState("");

  const [imageFIle, setImageFile] = useState("");

  const [show_schedule_edit, set_show_schedule_edit] = useState(false);

  const webinarDetails = useSelector((state) => state.Webinar);

  console.log(webinarDetails, "hhhhhhhhhhh");

  const navigate = useNavigate();

  const [initialState, setInitialState] = useState(initalForm);

  const [schedule, setSchedule] = useState({});

  const { Webinar_title, Webinar_language, Webinar_description, Webinar_name } =
    initialState;

  const [values, setValues] = useState([]);
  const [sesseionDate, setSessionDate] = useState();

  const [newPresenter, setnewPresenter] = useState({
    preview: "",
    profile_image: "",
    Presenters_name: "",
    Presenters_email: "",
    edit: false,
  });

  const { preview, profile_image, Presenters_name, Presenters_email } =
    newPresenter;
  const [error, setError] = useState();

  const [selectedSession, setSelectedSession] = useState(null);

  const [AddedSession, setAddedSession] = useState([]);

  const [newSession, setNewSession] = useState({});

  const handleSessionSelect = (session) => {
    setSelectedSession(session);
    setInitialState({ ...initialState, schedule_type: session });
    setAddedSession([]);
    setSchedule({ schedule_type: session });
  };

  // useEffect(() => {
  //   if (webinarDetails) {
  //     console.log(webinarDetails)
  //     setValues(webinarDetails.Presenters)
  //   }
  // }, [])

  const [startDate, setStartDate] = useState(new Date());

  const Changes_Lang_Handler = (value) => {
    setInitialState({ ...initialState, Webinar_language: value });
  };

  const changesHandler = async (e) => {
    const { id, value } = e.target;
    setInitialState({ ...initialState, [id]: value });
  };

  const addClick = async () => {
    const dat = await persenter_validation(newPresenter);
    console.log(dat, newPresenter, "newPresenter");
    if (isEmpty(dat)) {
      if (edit_presenter) {
        newPresenter.edit = true;
      }
      setValues([...values, newPresenter]);
      setnewPresenter({
        preview: "",
        image: "",
        Presenters_name: "",
        Presenters_email: "",
        edit: false,
      });
      setpreviewUrl("");
      setError();
    } else {
      setError(dat);
    }
  };

  function handleListChange(event) {
    const { id } = event.target;
    if (id == "image") {
      setEdit_Presenter(false);
      const { files } = event.target;
      setpreviewUrl(URL.createObjectURL(files[0]));
      setnewPresenter({
        ...newPresenter,
        [id]: files,
        preview: URL.createObjectURL(files[0]),
      });
    } else {
      setnewPresenter({ ...newPresenter, [id]: event.target.value });
    }
  }

  console.log(values, "values");
  const submitFormOne = async (e) => {
    try {
      e.preventDefault();

      const dat = await Basic_validation(initialState);

      if (showBasic_edit) {
        const result = [];

        if (webinarDetails.Presenters.length > 0) {
          console.log(values, initialState, webinarDetails, "showBasic_edit");

          const dep = await decryptString(id, true);
          const payload = {
            _id: webinarDetails._id,
            presenters: [...values, ...webinarDetails.Presenters],
            ...initialState,
            ...JSON.parse(dep),
          };

          console.log(payload, "payload");

          const { status, result } = await update_Webinar(payload);

          console.log(status, result, "status, result");
          if (status) {
            dispatch(setWebinar(result.result));
            toastAlert("success", "Webinar Updated");

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        }
      } else {
        if (isEmpty(dat)) {
          const dep = await decryptString(id, true);

          const payload = {
            presenters: values,
            ...initialState,
            ...JSON.parse(dep),
          };

          const { status, result } = await Create_Webinar(payload);

          console.log(status, result, "status, result");
          if (status) {
            toastAlert("success", "Basic Setting Created");
            const tkn = encryptString(result.result._id, true);

            setTimeout(() => {
              window.location.reload();
            }, 1000);
            dispatch(setWebinar(result.result));
          }
        } else {
          setError(dat);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancleHandler = async () => {
    navigate("/webinar");
  };

  console.log(selectedSession, "selectedSession", schedule);

  const scheduleHandler = async (e) => {
    const { id, value } = e.target;

    console.log(id, value, "id, value ");
    if (id == "all_timezone") {
      setSchedule({ ...schedule, [id]: !schedule.all_timezone });
    } else {
      setSchedule({ ...schedule, [id]: value });
    }
  };

  const sessionHandler = async (e) => {
    console.log(new Date(), "sessionHandler");
    console.log(new Date(e._d), "sessionHandler");
    const data = moment(e._d).format("LLL");
    console.log(data, "sessionHandler");
    setSessionDate(new Date(e._d));
  };

  const session_timeHandler = async (e) => {
    console.log(e, "sessionHandler");
    setSessionDate(new Date(e._d));
  };

  useEffect(() => {}, [sesseionDate]);

  const addSession = async () => {
    console.log(sesseionDate, newSession, "setAddedSession");
    let dat;
    if (selectedSession != "Recurring") {
      dat = await session_singleValid({
        session_date: sesseionDate,
        ...newSession,
      });
    } else {
      dat = await session_recurringValid({
        session_date: sesseionDate,
        ...newSession,
      });
    }
    console.log(dat, "addSession");
    if (isEmpty(dat)) {
      setError({});
      setAddedSession([
        ...AddedSession,
        { session_date: sesseionDate, ...newSession },
      ]);
    } else {
      setError(dat);
    }
  };

  console.log(AddedSession, "AddedSession");

  const submitFormTwo = async (e) => {
    try {
      e.preventDefault();
      // console.log(schedule, AddedSession, 'submitFormTwo')

      if (show_schedule_edit) {
        console.log(schedule, "schedule");
      } else {
        const det = await schedule_Valid(schedule);
        if (isEmpty(det)) {
          setError({});
          schedule.id = webinarDetails._id;
          schedule.session = AddedSession;
          const { status, result } = await add_webinar_schedule(schedule);
          console.log(status, result);
          dispatch(setWebinar(result.result));
        } else {
          setError(det);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const setFunction = async () => {
  //   try {

  //     dispatch(Webinar_Details(webinarDetails._id))

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // console.log(initialState, 'initialState')
  // (async function () {

  //   if (webinarDetails) {

  //     await setFunction()

  //   }
  // })()

  const set_Basic_Edit_Show = () => {
    setShow_Basicedit(!showBasic_edit);
  };

  const editPresenters = async (type, val, i) => {
    console.log(val, i, "val, i");
    if (type) {
      setEdit_Presenter(true);
      webinarDetails.Presenters.splice(i, 1);
      setnewPresenter({ ...val });
    } else {
      values.splice(i, 1);
      setpreviewUrl(val.preview);
      setnewPresenter({ ...val });
    }
  };
  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard">
          <Innerpagenavbar />
          <div className="dashbord-right mt-5">
            <div className="dashbord-right-body-1">
              <Accordion
                className="accMain"
                defaultActiveKey={["0"]}
                alwaysOpen
              >
                <Accordion.Item eventKey="0" className="accItem">
                  <Accordion.Header>
                    <p>Basic settings</p>
                    <div className="accordion-hearder-content">
                      {webinarDetails._id && showBasic_edit == false ? (
                        <>
                          <img
                            src={require("../../../assets/images/accordion-config.png")}
                            className="img-fluid"
                          />{" "}
                          <p>Configured</p>
                          <Link
                            className="accordionedit-btn"
                            onClick={set_Basic_Edit_Show}
                          >
                            Edit
                          </Link>
                        </>
                      ) : (
                        <>
                          <div>
                            <Link
                              to="#"
                              className="primary_btn primary_btn2 me-4"
                            >
                              <svg
                                width="8"
                                height="12"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.5 1L6.5 6L1.5 11"
                                  stroke="#001F2A"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              Cancel
                            </Link>
                            <Link
                              to="#"
                              className="primary_btn"
                              onClick={submitFormOne}
                            >
                              <img
                                src={require("../../../assets/images/chevron-right.png")}
                                height="16"
                                width="16"
                                className="img-fluid"
                              />{" "}
                              Confirm
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="white-box webinar-setting-content">
                      <Row>
                        <Col lg="2">
                          {" "}
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            {" "}
                            <Form.Label>Webinar language</Form.Label>{" "}
                            <ReactLanguageSelect
                              names={"international"}
                              // languages={languages.map(lang => lang.code)}
                              searchable={true}
                              searchPlaceholder="Search for a language"
                              aria-label="Default select example"
                              id="Webinar_language"
                              onSelect={(val) => Changes_Lang_Handler(val)}
                              className="language_select"
                            />
                            {error && error.Webinar_language && (
                              <span style={{ color: "red" }}>
                                {error.Webinar_language}
                              </span>
                            )}
                            {/* <Form.Select onClick={ChangesHandler} id="webinar_lang" aria-label="Default select example">
                          <option>English</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select> */}
                            <p>
                              The interface will be translated for your users
                            </p>
                          </Form.Group>
                        </Col>
                        <Col lg="3">
                          {" "}
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Webinar name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: Webinar for my free leads"
                              id="Webinar_name"
                              defaultValue={webinarDetails?.Webinar_name}
                              onChange={changesHandler}
                            />
                            {error && error.Webinar_name && (
                              <span style={{ color: "red" }}>
                                {error.Webinar_name}
                              </span>
                            )}

                            <p>
                              Private title. for your reference only. Max 70
                              characters.
                            </p>
                          </Form.Group>
                        </Col>
                        <Col lg="3">
                          {" "}
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Webinar title</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: How to lose weight in 6 weeks"
                              id="Webinar_title"
                              defaultValue={webinarDetails?.Webinar_title}
                              onChange={changesHandler}
                            />
                            <p>Public title. Max 70 characters.</p>
                            {error && error.Webinar_title && (
                              <span style={{ color: "red" }}>
                                {error.Webinar_title}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg="4">
                          {" "}
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Webinar description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: All the tips and tricks to efficient diets and workouts"
                              id="Webinar_description"
                              defaultValue={webinarDetails?.Webinar_description}
                              onChange={changesHandler}
                            />
                            <p>Public description. Max 150 characters.</p>
                            {error && error.Webinar_description && (
                              <span style={{ color: "red" }}>
                                {error.Webinar_description}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col lg="5">
                        <div className="webinar-setting-content1 webinar-width">
                          <p className="webinar-setting-content1-head">
                            Presenters
                          </p>
                          {webinarDetails?.Presenters?.length > 0
                            ? webinarDetails.Presenters.map((val, i) => (
                                <div>
                                  <div className="profile-present">
                                    {" "}
                                    <img
                                      src={`${config.API_URL}/presenter/${val.profile_image}`}
                                      className="img-fluid"
                                    />{" "}
                                    <div className="profile-detail">
                                      <div>
                                        <div className="name-content">
                                          <p>{val.Presenters_name}</p>
                                          <div className="verify">
                                            {" "}
                                            <img
                                              src={require("../../../assets/images/verify-img.png")}
                                              className="img-fluid"
                                            />{" "}
                                            <p>Verified</p>
                                          </div>
                                        </div>
                                        <p className="email-profile">
                                          {val.Presenters_email}
                                        </p>
                                      </div>{" "}
                                      <div className="profile-change">
                                        <img
                                          src={require("../../../assets/images/setting-img.png")}
                                          className="img-fluid"
                                        />{" "}
                                        <img
                                          src={require("../../../assets/images/voice-img.png")}
                                          className="img-fluid"
                                        />{" "}
                                        <Link
                                          className="profile-edit"
                                          onClick={() =>
                                            editPresenters(true, val, i)
                                          }
                                        >
                                          Edit
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : ""}

                          {values?.length > 0 && (
                            <>
                              <div className="white-box1 webinar-setting-sub">
                                {values.map((list, i) => (
                                  <div>
                                    <div className="profile-present">
                                      {" "}
                                      {list.edit ? (
                                        <img
                                          src={`${config.API_URL}/presenter/${list.profile_image}`}
                                          className="img-fluid"
                                        />
                                      ) : (
                                        <img
                                          src={list.preview}
                                          className="img-fluid"
                                        />
                                      )}
                                      <div className="profile-detail">
                                        <div>
                                          <div className="name-content">
                                            <p>{list.Presenters_name}</p>
                                            <div className="verify">
                                              {" "}
                                              <img
                                                src={require("../../../assets/images/verify-img.png")}
                                                className="img-fluid"
                                              />{" "}
                                              <p>Verified</p>
                                            </div>
                                          </div>
                                          <p className="email-profile">
                                            {list.Presenters_email}
                                          </p>
                                        </div>{" "}
                                        <div className="profile-change">
                                          <img
                                            src={require("../../../assets/images/setting-img.png")}
                                            className="img-fluid"
                                          />{" "}
                                          <img
                                            src={require("../../../assets/images/voice-img.png")}
                                            className="img-fluid"
                                          />{" "}
                                          <Link
                                            className="profile-edit"
                                            onClick={() =>
                                              editPresenters(false, list, i)
                                            }
                                          >
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col lg="7">
                        {" "}
                        <div className="webinar-setting-content1 webinar-width1">
                          <div>
                            <p className="webinar-setting-content1-head">
                              New Presenters
                            </p>
                            <div className="white-box1 webinar-setting-sub">
                              <div className=" NewPresenters">
                                {" "}
                                <Form.Control
                                  ref={fileRef}
                                  id="image"
                                  type="file"
                                  className="img_upload"
                                  accept=".png,.jpg,.jpeg,.webp"
                                  // defaultValue={ }
                                  onChange={handleListChange}
                                />
                                {/* <i class="bi bi-folder-plus upload"  style={{ fontSize: '30px' }}></i> */}
                                {edit_presenter == false ? (
                                  <>
                                    {previewUrl != "" ? (
                                      <img
                                        src={previewUrl}
                                        onClick={() => fileRef.current.click()}
                                        className="img-fluid"
                                      />
                                    ) : (
                                      <>
                                        <img
                                          src={require("../../../assets/images/profile-img2.png")}
                                          onClick={() =>
                                            fileRef.current.click()
                                          }
                                          className="img-fluid"
                                        />
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {previewUrl != "" ? (
                                      <img
                                        src={previewUrl}
                                        onClick={() => fileRef.current.click()}
                                        className="img-fluid"
                                      />
                                    ) : (
                                      <>
                                        <img
                                          src={`${config.API_URL}/presenter/${profile_image}`}
                                          onClick={() =>
                                            fileRef.current.click()
                                          }
                                          className="img-fluid"
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                                {error && error.image && (
                                  <span style={{ color: "red" }}>
                                    {error.image}
                                  </span>
                                )}
                                <div className="mail-box">
                                  <Row>
                                    <Col lg="5">
                                      {" "}
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter the first name and last name"
                                        id="Presenters_name"
                                        value={
                                          Presenters_name ? Presenters_name : ""
                                        }
                                        onChange={handleListChange}
                                      />
                                      {error && error.Presenters_name && (
                                        <span style={{ color: "red" }}>
                                          {error.Presenters_name}
                                        </span>
                                      )}
                                    </Col>

                                    <Col lg="7">
                                      {" "}
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter the email..."
                                        id="Presenters_email"
                                        value={
                                          Presenters_email
                                            ? Presenters_email
                                            : ""
                                        }
                                        onChange={handleListChange}
                                      />{" "}
                                      {error && error.Presenters_email && (
                                        <span style={{ color: "red" }}>
                                          {error.Presenters_email}
                                        </span>
                                      )}
                                    </Col>
                                  </Row>
                                  <Link
                                    className="profile-edit"
                                    onClick={addClick}
                                  >
                                    Add
                                  </Link>
                                </div>
                              </div>
                              {/* <Row>
                      <Col lg="5">
                        {" "}
                       
                      </Col>
                      <Col lg="7">
                        {" "}
                       
                      </Col>
                    </Row> */}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <p>Webinar schedule</p>
                    <div className="accordion-hearder-content">
                      {show_schedule_edit == false &&
                      webinarDetails?.Webinar_schedule?.type ? (
                        <>
                          <img
                            src={require("../../../assets/images/accordion-config.png")}
                            className="img-fluid"
                          />{" "}
                          <p>Configured</p>
                          <Link
                            className="accordionedit-btn"
                            onClick={() => set_show_schedule_edit(true)}
                          >
                            Edit
                          </Link>
                        </>
                      ) : (
                        <>
                          <div>
                            <Link
                              to="#"
                              className="primary_btn primary_btn2 me-4"
                            >
                              <svg
                                width="8"
                                height="12"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.5 1L6.5 6L1.5 11"
                                  stroke="#001F2A"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              Cancel
                            </Link>
                            <Link
                              to="#"
                              className="primary_btn"
                              onClick={submitFormTwo}
                            >
                              <img
                                src={require("../../../assets/images/chevron-right.png")}
                                height="16"
                                width="16"
                                className="img-fluid"
                              />{" "}
                              Confirm
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="time-mangement">
                      <div
                        className={`time-schedule ${
                          selectedSession === "single" ? "box-shadow" : ""
                        }`}
                      >
                        <div class="form-check check-head">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            value="single"
                            checked={selectedSession === "single"}
                            onChange={() => handleSessionSelect("single")}
                          />
                          {selectedSession === "single"
                            ? error &&
                              error.schedule_type && (
                                <span style={{ color: "red" }}>
                                  {error.schedule_type}
                                </span>
                              )
                            : ""}
                          <label
                            class="form-check-label"
                            for="flexRadioDefault2"
                          >
                            One single session
                          </label>
                        </div>
                        <p>
                          The registration box will display one (or many)
                          scheduled events, and your users will register to only
                          one of them at a time
                        </p>
                        <div
                          className="schedule-details"
                          style={{
                            backgroundColor:
                              selectedSession === "single" ? "#effeff" : "#fff",
                          }}
                        >
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={require("../../../assets/images/schedule-img1.png")}
                                className="img-fluid"
                              />{" "}
                            </InputGroup.Text>
                            <Form.Control
                              placeholder="Username"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={
                                selectedSession == "single"
                                  ? schedule?.name
                                    ? schedule.name
                                    : selectedSession ==
                                        webinarDetails.Webinar_schedule?.type &&
                                      webinarDetails.Webinar_schedule?.user_name
                                    ? webinarDetails.Webinar_schedule?.user_name
                                    : ""
                                  : ""
                              }
                              id="name"
                              disabled={
                                selectedSession == "single" ? false : true
                              }
                              onChange={scheduleHandler}
                            />
                          </InputGroup>
                          {selectedSession === "single"
                            ? error &&
                              error.schedule_name && (
                                <span style={{ color: "red" }}>
                                  {error.schedule_name}
                                </span>
                              )
                            : ""}

                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={require("../../../assets/images/schedule-img2.png")}
                                className="img-fluid"
                              />{" "}
                            </InputGroup.Text>
                            <Form.Control
                              placeholder="Email"
                              aria-label="Email"
                              aria-describedby="basic-addon1"
                              value={
                                selectedSession == "single"
                                  ? schedule?.email
                                    ? schedule.email
                                    : selectedSession ==
                                        webinarDetails.Webinar_schedule?.type &&
                                      webinarDetails.Webinar_schedule?.email
                                    ? webinarDetails.Webinar_schedule?.email
                                    : ""
                                  : ""
                              }
                              // defaultValue={ }
                              id="email"
                              disabled={
                                selectedSession == "single" ? false : true
                              }
                              onChange={scheduleHandler}
                            />
                          </InputGroup>
                          {selectedSession === "single"
                            ? error &&
                              error.schedule_email && (
                                <span style={{ color: "red" }}>
                                  {error.schedule_email}
                                </span>
                              )
                            : ""}

                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={require("../../../assets/images/schedule-img3.png")}
                                className="img-fluid"
                              />{" "}
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              placeholder="Mobile"
                              aria-label="Mobile"
                              aria-describedby="basic-addon1"
                              value={
                                selectedSession == "single"
                                  ? schedule?.mobile
                                    ? schedule.mobile
                                    : selectedSession ==
                                        webinarDetails.Webinar_schedule?.type &&
                                      webinarDetails.Webinar_schedule?.mobile
                                    ? webinarDetails.Webinar_schedule?.mobile
                                    : ""
                                  : ""
                              }
                              id="mobile"
                              disabled={
                                selectedSession == "single" ? false : true
                              }
                              onChange={scheduleHandler}
                            />
                          </InputGroup>
                          {selectedSession === "single"
                            ? error &&
                              error.schedule_mobile && (
                                <span style={{ color: "red" }}>
                                  {error.schedule_mobile}
                                </span>
                              )
                            : ""}

                          <h6>Select an event</h6>
                          <div class="form-check time-event">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              // id="exampleRadios1"
                              value="Thursday 7th May, 5:00 pm"
                              id="event"
                              disabled={
                                selectedSession == "single" ? false : true
                              }
                              onChange={scheduleHandler}
                              defaultChecked={
                                selectedSession ==
                                  webinarDetails?.Webinar_schedule?.type &&
                                webinarDetails?.Webinar_schedule?.event ==
                                  "Thursday 7th May, 5:00 pm"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              class="form-check-label"
                              for="exampleRadios1"
                            >
                              Thursday 7th May, 5:00 pm
                            </label>
                          </div>
                          <div class="form-check time-event">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              value="Friday 8th May, 7:30 pm"
                              id="event"
                              disabled={
                                selectedSession == "single" ? false : true
                              }
                              onChange={scheduleHandler}
                              defaultChecked={
                                selectedSession ==
                                  webinarDetails?.Webinar_schedule?.type &&
                                webinarDetails?.Webinar_schedule?.event ==
                                  "Friday 8th May, 7:30 pm"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              class="form-check-label"
                              for="exampleRadios2"
                            >
                              Friday 8th May, 7:30 pm
                            </label>
                          </div>
                          <div class="form-check time-event">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              value="Saturday 9th May, 3:00 pm"
                              id="event"
                              disabled={
                                selectedSession == "single" ? false : true
                              }
                              onChange={scheduleHandler}
                              defaultChecked={
                                selectedSession ==
                                  webinarDetails?.Webinar_schedule?.type &&
                                webinarDetails?.Webinar_schedule?.event ==
                                  "Saturday 9th May, 3:00 pm"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              class="form-check-label"
                              for="exampleRadios3"
                            >
                              Saturday 9th May, 3:00 pm
                            </label>
                          </div>

                          {selectedSession === "single"
                            ? error &&
                              error.schedule_event && (
                                <span style={{ color: "red" }}>
                                  {error.schedule_event}
                                </span>
                              )
                            : ""}
                        </div>
                      </div>
                      <div
                        className={`time-schedule ${
                          selectedSession === "multiple" ? "box-shadow" : ""
                        }`}
                      >
                        <div class="form-check check-head">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            value="second"
                            checked={selectedSession === "multiple"}
                            onChange={() => handleSessionSelect("multiple")}
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Multiple Session
                          </label>
                        </div>
                        <p>
                          The registration box will display one (or many)
                          scheduled events, and your users will register to only
                          one of them at a time
                        </p>
                        <div
                          className="schedule-details"
                          style={{
                            backgroundColor:
                              selectedSession === "second" ? "#effeff" : "#fff",
                          }}
                        >
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={require("../../../assets/images/schedule-img1.png")}
                                className="img-fluid"
                              />{" "}
                            </InputGroup.Text>
                            <Form.Control
                              placeholder="Username"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={
                                selectedSession == "multiple"
                                  ? schedule?.name
                                    ? schedule.name
                                    : selectedSession ==
                                        webinarDetails?.Webinar_schedule
                                          ?.type &&
                                      webinarDetails?.Webinar_schedule
                                        ?.user_name
                                    ? webinarDetails?.Webinar_schedule
                                        ?.user_name
                                    : ""
                                  : ""
                              }
                              id="name"
                              disabled={
                                selectedSession == "multiple" ? false : true
                              }
                              onChange={scheduleHandler}
                            />
                          </InputGroup>
                          {selectedSession === "multiple"
                            ? error &&
                              error.schedule_name && (
                                <span style={{ color: "red" }}>
                                  {error.schedule_name}
                                </span>
                              )
                            : ""}

                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={require("../../../assets/images/schedule-img2.png")}
                                className="img-fluid"
                              />{" "}
                            </InputGroup.Text>
                            <Form.Control
                              placeholder="Email"
                              aria-label="Email"
                              aria-describedby="basic-addon1"
                              value={
                                selectedSession == "multiple"
                                  ? schedule?.email
                                    ? schedule.email
                                    : selectedSession ==
                                        webinarDetails?.Webinar_schedule
                                          ?.type &&
                                      webinarDetails.Webinar_schedule?.email
                                    ? webinarDetails.Webinar_schedule?.email
                                    : ""
                                  : ""
                              }
                              id="email"
                              disabled={
                                selectedSession == "multiple" ? false : true
                              }
                              onChange={scheduleHandler}
                            />
                          </InputGroup>
                          {selectedSession === "multiple"
                            ? error &&
                              error.schedule_email && (
                                <span style={{ color: "red" }}>
                                  {error.schedule_email}
                                </span>
                              )
                            : ""}

                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={require("../../../assets/images/schedule-img3.png")}
                                className="img-fluid"
                              />{" "}
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              placeholder="Mobile"
                              aria-label="Mobile"
                              aria-describedby="basic-addon1"
                              value={
                                selectedSession == "multiple"
                                  ? schedule?.mobile
                                    ? schedule.mobile
                                    : selectedSession ==
                                        webinarDetails.Webinar_schedule?.type &&
                                      webinarDetails.Webinar_schedule?.mobile
                                    ? webinarDetails.Webinar_schedule?.mobile
                                    : ""
                                  : ""
                              }
                              // value={selectedSession == 'multiple' ? schedule?.mobile ? schedule.mobile : '' : ''}
                              id="mobile"
                              disabled={
                                selectedSession == "multiple" ? false : true
                              }
                              onChange={scheduleHandler}
                            />
                          </InputGroup>
                          {selectedSession === "multiple"
                            ? error &&
                              error.schedule_mobile && (
                                <span style={{ color: "red" }}>
                                  {error.schedule_mobile}
                                </span>
                              )
                            : ""}

                          <h6>Select an event</h6>
                          <div class="form-check time-event">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              value="Thursday 7th May, 5:00 pm"
                              id="event"
                              disabled={
                                selectedSession == "multiple" ? false : true
                              }
                              onChange={scheduleHandler}
                              defaultChecked={
                                selectedSession ==
                                  webinarDetails.Webinar_schedule?.type &&
                                webinarDetails.Webinar_schedule?.event ==
                                  "Thursday 7th May, 5:00 pm"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              class="form-check-label"
                              for="exampleRadios1"
                            >
                              Thursday 7th May, 5:00 pm
                            </label>
                          </div>
                          <div class="form-check time-event">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              value="Friday 8th May, 7:30 pm"
                              id="event"
                              disabled={
                                selectedSession == "multiple" ? false : true
                              }
                              onChange={scheduleHandler}
                              defaultChecked={
                                selectedSession ==
                                  webinarDetails.Webinar_schedule?.type &&
                                webinarDetails.Webinar_schedule?.event ==
                                  "Friday 8th May, 7:30 pm"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              class="form-check-label"
                              for="exampleRadios2"
                            >
                              Friday 8th May, 7:30 pm
                            </label>
                          </div>
                          <div class="form-check time-event">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              value="Saturday 9th May, 3:00 pm"
                              id="event"
                              disabled={
                                selectedSession == "multiple" ? false : true
                              }
                              onChange={scheduleHandler}
                              defaultChecked={
                                selectedSession ==
                                  webinarDetails.Webinar_schedule?.type &&
                                webinarDetails.Webinar_schedule?.event ==
                                  "Saturday 9th May, 3:00 pm"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              class="form-check-label"
                              for="exampleRadios3"
                            >
                              Saturday 9th May, 3:00 pm
                            </label>
                          </div>

                          {selectedSession === "multiple"
                            ? error &&
                              error.schedule_event && (
                                <span style={{ color: "red" }}>
                                  {error.schedule_event}
                                </span>
                              )
                            : ""}
                        </div>
                      </div>
                      <div
                        className={`time-schedule ${
                          selectedSession === "Recurring" ? "box-shadow" : ""
                        }`}
                      >
                        <div class="form-check check-head">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            value="Recurring"
                            checked={selectedSession === "Recurring"}
                            onChange={() => handleSessionSelect("Recurring")}
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Recurring Series Of Session
                          </label>
                        </div>
                        <p>
                          The registration box will display one (or many)
                          scheduled events, and your users will register to only
                          one of them at a time
                        </p>
                        <div
                          className="schedule-details"
                          style={{
                            backgroundColor:
                              selectedSession === "third" ? "#effeff" : "#fff",
                          }}
                        >
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={require("../../../assets/images/schedule-img1.png")}
                                className="img-fluid"
                              />{" "}
                            </InputGroup.Text>
                            <Form.Control
                              placeholder="Username"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={
                                selectedSession == "Recurring"
                                  ? schedule?.name
                                    ? schedule.name
                                    : selectedSession ==
                                        webinarDetails.Webinar_schedule?.type &&
                                      webinarDetails.Webinar_schedule?.user_name
                                    ? webinarDetails.Webinar_schedule?.user_name
                                    : ""
                                  : ""
                              }
                              id="name"
                              disabled={
                                selectedSession == "Recurring" ? false : true
                              }
                              onChange={scheduleHandler}
                            />
                          </InputGroup>
                          {selectedSession === "Recurring"
                            ? error &&
                              error.schedule_name && (
                                <span style={{ color: "red" }}>
                                  {error.schedule_name}
                                </span>
                              )
                            : ""}
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={require("../../../assets/images/schedule-img2.png")}
                                className="img-fluid"
                              />{" "}
                            </InputGroup.Text>
                            <Form.Control
                              placeholder="Email"
                              aria-label="Email"
                              aria-describedby="basic-addon1"
                              value={
                                selectedSession == "Recurring"
                                  ? schedule?.email
                                    ? schedule.email
                                    : selectedSession ==
                                        webinarDetails.Webinar_schedule?.type &&
                                      webinarDetails.Webinar_schedule?.email
                                    ? webinarDetails.Webinar_schedule?.email
                                    : ""
                                  : ""
                              }
                              id="email"
                              disabled={
                                selectedSession == "Recurring" ? false : true
                              }
                              onChange={scheduleHandler}
                            />
                          </InputGroup>
                          {selectedSession === "Recurring"
                            ? error &&
                              error.schedule_email && (
                                <span style={{ color: "red" }}>
                                  {error.schedule_email}
                                </span>
                              )
                            : ""}

                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={require("../../../assets/images/schedule-img3.png")}
                                className="img-fluid"
                              />{" "}
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              placeholder="Mobile"
                              aria-label="Mobile"
                              aria-describedby="basic-addon1"
                              value={
                                selectedSession == "Recurring"
                                  ? schedule?.mobile
                                    ? schedule.mobile
                                    : selectedSession ==
                                        webinarDetails.Webinar_schedule?.type &&
                                      webinarDetails.Webinar_schedule?.mobile
                                    ? webinarDetails.Webinar_schedule?.mobile
                                    : ""
                                  : ""
                              }
                              id="mobile"
                              disabled={
                                selectedSession == "Recurring" ? false : true
                              }
                              onChange={scheduleHandler}
                            />
                          </InputGroup>
                          {selectedSession === "Recurring"
                            ? error &&
                              error.schedule_mobile && (
                                <span style={{ color: "red" }}>
                                  {error.schedule_mobile}
                                </span>
                              )
                            : ""}

                          <h6>Select an event</h6>
                          <div class="form-check time-event">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              value="Thursday 7th May, 5:00 pm"
                              id="event"
                              disabled={
                                selectedSession == "Recurring" ? false : true
                              }
                              onChange={scheduleHandler}
                              defaultChecked={
                                selectedSession ==
                                  webinarDetails.Webinar_schedule?.type &&
                                webinarDetails.Webinar_schedule?.event ==
                                  "Thursday 7th May, 5:00 pm"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              class="form-check-label"
                              for="exampleRadios1"
                            >
                              Thursday 7th May, 5:00 pm
                            </label>
                          </div>
                          <div class="form-check time-event">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              value="Friday 8th May, 7:30 pm"
                              id="event"
                              disabled={
                                selectedSession == "Recurring" ? false : true
                              }
                              onChange={scheduleHandler}
                              defaultChecked={
                                selectedSession ==
                                  webinarDetails.Webinar_schedule?.type &&
                                webinarDetails.Webinar_schedule?.event ==
                                  "Friday 8th May, 7:30 pm"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              class="form-check-label"
                              for="exampleRadios2"
                            >
                              Friday 8th May, 7:30 pm
                            </label>
                          </div>
                          <div class="form-check time-event">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              value="Saturday 9th May, 3:00 pm"
                              id="event"
                              disabled={
                                selectedSession == "Recurring" ? false : true
                              }
                              onChange={scheduleHandler}
                              defaultChecked={
                                selectedSession ==
                                  webinarDetails.Webinar_schedule?.type &&
                                webinarDetails.Webinar_schedule?.event ==
                                  "Saturday 9th May, 3:00 pm"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              class="form-check-label"
                              for="exampleRadios3"
                            >
                              Saturday 9th May, 3:00 pm
                            </label>
                          </div>

                          {selectedSession === "Recurring"
                            ? error &&
                              error.schedule_event && (
                                <span style={{ color: "red" }}>
                                  {error.schedule_event}
                                </span>
                              )
                            : ""}
                        </div>
                      </div>
                    </div>

                    {selectedSession == "Recurring" ? (
                      <div className="newSission-bg">
                        <div className="newSission">
                          <p>New Series</p>
                          <Link className="onbtn">EveryDay</Link>
                          <InputGroup className="">
                            <InputGroup.Text id="basic-addon1">
                              {" "}
                              <img
                                src={require("../../../assets/images/calender.png")}
                                className="img-fluid"
                              />{" "}
                            </InputGroup.Text>
                            <Datetime
                              initialValue={new Date()}
                              timeFormat={false}
                              onChange={sessionHandler}
                              // dateFormat="DD-MM-YY"
                            />
                          </InputGroup>
                          {error && error.session_date && (
                            <span style={{ color: "red" }}>
                              {error.session_date}
                            </span>
                          )}
                          <InputGroup className="">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={require("../../../assets/images/time.png")}
                                className="img-fluid"
                              />{" "}
                            </InputGroup.Text>
                            <Datetime
                              value={sesseionDate ? sesseionDate : ""}
                              // initialValue={sesseionDate}
                              dateFormat={false}
                              timeFormat={"hh:mm:ss"}
                              onChange={session_timeHandler}
                            />
                          </InputGroup>
                          <Link className="onbtn">Limit</Link>
                          <InputGroup className="">
                            <Form.Control
                              placeholder="Limit"
                              id="limit"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              onClick={(e) =>
                                setNewSession({
                                  ...newSession,
                                  limit: e.target.value,
                                })
                              }
                            />
                          </InputGroup>
                          {error && error.limit && (
                            <span style={{ color: "red" }}>{error.limit}</span>
                          )}

                          <Form.Select
                            aria-label="Default select example"
                            onClick={(e) =>
                              setNewSession({
                                ...newSession,
                                timezone: e.target.value,
                              })
                            }
                          >
                            <option>Select time zone</option>
                            <option value="IST">IST</option>
                            <option value="UST">UST</option>
                          </Form.Select>
                          {error && error.timezone && (
                            <span style={{ color: "red" }}>
                              {error.timezone}
                            </span>
                          )}
                          <Link
                            className="newsission-addbtn"
                            onClick={addSession}
                          >
                            Add
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div className="newSission-bg">
                        <div className="newSission">
                          <p>New Session</p>
                          <Link className="onbtn">On</Link>
                          <InputGroup className="">
                            <InputGroup.Text id="basic-addon1">
                              {" "}
                              <img
                                src={require("../../../assets/images/calender.png")}
                                className="img-fluid"
                              />{" "}
                            </InputGroup.Text>

                            <Datetime
                              initialValue={new Date()}
                              timeFormat={false}
                              onChange={sessionHandler}
                              // dateFormat="DD-MM-YY"
                            />
                            {/* <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          /> */}
                          </InputGroup>
                          {error && error.session_date && (
                            <span style={{ color: "red" }}>
                              {error.session_date}
                            </span>
                          )}
                          <InputGroup className="">
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={require("../../../assets/images/time.png")}
                                className="img-fluid"
                              />{" "}
                            </InputGroup.Text>
                            <Datetime
                              value={sesseionDate ? sesseionDate : ""}
                              // initialValue={sesseionDate}
                              dateFormat={false}
                              timeFormat={"hh:mm:ss"}
                              onChange={session_timeHandler}
                            />
                            {/* <Form.Control
                            placeholder="Select time"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          /> */}
                          </InputGroup>
                          <Form.Select
                            aria-label="Default select example"
                            onClick={(e) =>
                              setNewSession({ timezone: e.target.value })
                            }
                          >
                            <option>Select time zone</option>
                            <option value="IST">IST</option>
                            <option value="UST">UST</option>
                          </Form.Select>
                          {error && error.timezone && (
                            <span style={{ color: "red" }}>
                              {error.timezone}
                            </span>
                          )}

                          <Link
                            className="newsission-addbtn"
                            onClick={addSession}
                          >
                            Add
                          </Link>
                        </div>
                      </div>
                    )}

                    {webinarDetails?.Webinar_schedule?.sessions.length > 0 &&
                      selectedSession ==
                        webinarDetails.Webinar_schedule?.type &&
                      webinarDetails?.Webinar_schedule?.sessions.map((list) => (
                        <>
                          {selectedSession == "Recurring" ? (
                            <div className="newSission-bg">
                              <div className="newSission">
                                {/* <p>New Series</p> */}
                                <Link className="onbtn">EveryDay</Link>
                                <InputGroup className="">
                                  <InputGroup.Text id="basic-addon1">
                                    {" "}
                                    <img
                                      src={require("../../../assets/images/calender.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </InputGroup.Text>
                                  <Datetime
                                    value={new Date(list.session_date)}
                                    timeFormat={false}
                                    onChange={sessionHandler}
                                    dateFormat="DD-MM-YY"
                                    open={false}
                                  />
                                </InputGroup>
                                <InputGroup className="">
                                  <InputGroup.Text id="basic-addon1">
                                    <img
                                      src={require("../../../assets/images/time.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </InputGroup.Text>
                                  <Datetime
                                    value={new Date(list.session_date)}
                                    // initialValue={sesseionDate}
                                    dateFormat={false}
                                    timeFormat={"hh:mm:ss"}
                                    onChange={session_timeHandler}
                                    open={false}
                                  />
                                </InputGroup>
                                <Link className="onbtn">Limit</Link>
                                <InputGroup className="">
                                  <Form.Control
                                    placeholder="Limit"
                                    id="limit"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={list.limit}
                                    // onClick={(e) => setNewSession({ ...newSession, "limit": e.target.value })}
                                  />
                                </InputGroup>

                                <Form.Select aria-label="Default select example">
                                  <option>{list.timezone}</option>
                                  {/* <option value="IST">IST</option>
                                <option value="UST">UST</option> */}
                                </Form.Select>
                                <Link
                                  className="newsission-addbtn"
                                  onClick={addSession}
                                >
                                  Edit
                                </Link>
                              </div>
                            </div>
                          ) : (
                            <div className="newSission-bg">
                              <div className="newSission">
                                {/* <p>New Session</p> */}
                                <Link className="onbtn">On</Link>
                                <InputGroup className="">
                                  <InputGroup.Text id="basic-addon1">
                                    {" "}
                                    <img
                                      src={require("../../../assets/images/calender.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </InputGroup.Text>

                                  <Datetime
                                    initialValue={list.session_date}
                                    timeFormat={false}
                                    // dateFormat="DD-MM-YY"
                                    open={false}
                                  />
                                  {/* <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          /> */}
                                </InputGroup>
                                <InputGroup className="">
                                  <InputGroup.Text id="basic-addon1">
                                    <img
                                      src={require("../../../assets/images/time.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </InputGroup.Text>
                                  <Datetime
                                    value={list.session_date}
                                    // initialValue={sesseionDate}
                                    dateFormat={false}
                                    timeFormat={"hh:mm:ss"}
                                    onChange={session_timeHandler}
                                    open={false}
                                  />
                                  {/* <Form.Control
                            placeholder="Select time"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          /> */}
                                </InputGroup>
                                <Form.Select aria-label="Default select example">
                                  <option>{list.timezone}</option>
                                  {/* <option >IST</option> */}
                                  {/* <option value="IST">UST</option> */}
                                </Form.Select>
                                <Link className="newsission-addbtn">Edit</Link>
                              </div>
                            </div>
                          )}
                        </>
                      ))}

                    {AddedSession.length > 0 &&
                      AddedSession.map((list) => (
                        <>
                          {selectedSession == "Recurring" ? (
                            <div className="newSission-bg">
                              <div className="newSission">
                                {/* <p>New Series</p> */}
                                <Link className="onbtn">EveryDay</Link>
                                <InputGroup className="">
                                  <InputGroup.Text id="basic-addon1">
                                    {" "}
                                    <img
                                      src={require("../../../assets/images/calender.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </InputGroup.Text>
                                  <Datetime
                                    initialValue={list.session_date}
                                    timeFormat={false}
                                    onChange={sessionHandler}
                                    // dateFormat="DD-MM-YY"
                                  />
                                </InputGroup>
                                <InputGroup className="">
                                  <InputGroup.Text id="basic-addon1">
                                    <img
                                      src={require("../../../assets/images/time.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </InputGroup.Text>
                                  <Datetime
                                    value={list.session_date}
                                    // initialValue={sesseionDate}
                                    dateFormat={false}
                                    timeFormat={"hh:mm:ss"}
                                    onChange={session_timeHandler}
                                  />
                                </InputGroup>
                                <Link className="onbtn">Limit</Link>
                                <InputGroup className="">
                                  <Form.Control
                                    placeholder="Limit"
                                    id="limit"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={list.limit}
                                    // onClick={(e) => setNewSession({ ...newSession, "limit": e.target.value })}
                                  />
                                </InputGroup>

                                <Form.Select aria-label="Default select example">
                                  <option>{list.timezone}</option>
                                  {/* <option value="IST">IST</option>
                                <option value="UST">UST</option> */}
                                </Form.Select>
                                <Link
                                  className="newsission-addbtn"
                                  onClick={addSession}
                                >
                                  Add
                                </Link>
                              </div>
                            </div>
                          ) : (
                            <div className="newSission-bg">
                              <div className="newSission">
                                {/* <p>New Session</p> */}
                                <Link className="onbtn">On</Link>
                                <InputGroup className="">
                                  <InputGroup.Text id="basic-addon1">
                                    {" "}
                                    <img
                                      src={require("../../../assets/images/calender.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </InputGroup.Text>

                                  <Datetime
                                    initialValue={list.session_date}
                                    timeFormat={false}
                                    onChange={sessionHandler}
                                    // dateFormat="DD-MM-YY"
                                  />
                                  {/* <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          /> */}
                                </InputGroup>
                                <InputGroup className="">
                                  <InputGroup.Text id="basic-addon1">
                                    <img
                                      src={require("../../../assets/images/time.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </InputGroup.Text>
                                  <Datetime
                                    value={list.session_date}
                                    // initialValue={sesseionDate}
                                    dateFormat={false}
                                    timeFormat={"hh:mm:ss"}
                                    onChange={session_timeHandler}
                                  />
                                  {/* <Form.Control
                            placeholder="Select time"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          /> */}
                                </InputGroup>
                                <Form.Select aria-label="Default select example">
                                  <option>{list.timezone}</option>
                                  {/* <option >IST</option> */}
                                  {/* <option value="IST">UST</option> */}
                                </Form.Select>
                                <Link
                                  className="newsission-addbtn"
                                  onClick={addSession}
                                >
                                  Add
                                </Link>
                              </div>
                            </div>
                          )}
                        </>
                      ))}

                    <div className="timezoneconversion-head">
                      <div className="timezoneconversion">
                        <h6>Allow time zone conversion</h6>
                        <p>
                          The Registration form will allow your visitors to
                          convert your configured time zone to theirs, so it's
                          more convenient for them. However, you may turn this
                          option OFF and therefore all times will always be
                          displayed in your configured time zone
                        </p>
                      </div>
                      <div>
                        <Form.Check
                          type="switch"
                          id="all_timezone"
                          onClick={scheduleHandler}
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <p>Registration page design</p>
                    <div className="accordion-header-content">
                      {/* <div>
            <Link to="#" className="primary_btn primary_btn2 me-4">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 1L6.5 6L1.5 11"
                  stroke="#001F2A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Cancel
            </Link>
            <Link to="#" className="primary_btn">
              <img
                src={require("../../../assets/images/chevron-right.png")}
                height="16"
                width="16"
                className="img-fluid"
              />{" "}
              Confirm
            </Link>
          </div> */}
                      <img
                        src={require("../../../assets/images/accordion-config.png")}
                        className="img-fluid"
                      />{" "}
                      <p>Configured</p>
                      <Link className="accordionedit-btn">Edit</Link>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="dashbordrightbody-list1 registration_alert">
                      <h6>
                        All emails notifications will be sent out from{" "}
                        <span>"Kevin Peterson - kevin@peterson.com"</span>. If
                        you'd like to change it, read this article.
                      </h6>
                      <div className="pre_notification">
                        <ul className="pre_notification_list">
                          <li className="list_msg">
                            <h4 className="mb-2">Pre-Webinar notifications</h4>
                            <p>
                              Keep them in the loop of the webinar they've
                              registered for, It's a great opportunity to hand
                              them their link to the webinar room, and to remind
                              them of the topics that will be covered. It helps
                              greatly to maximize attendance!
                            </p>
                            <ul className="pre_notification_1 mt-3">
                              <li>
                                <div className="confirm_msg">
                                  <div className="add_info">
                                    <h4>Register Confirmation Message</h4>
                                    <div className="add_info_span">
                                      <a href="#">Add Phone</a>
                                      <a href="#" onClick={handleEmailShow}>
                                        Add Email
                                      </a>
                                    </div>
                                  </div>
                                  <div className="confirm_msg_content mt-4">
                                    <div className="add_info_content">
                                      <img
                                        src={require("../../../assets/images/registration_imgs/msg.png")}
                                        className="img-fluid"
                                      />
                                      <div>
                                        <h4>Immediately</h4>
                                        <p>[Confirmation] Your webinar link</p>
                                      </div>
                                    </div>
                                    <div className="add_info_img">
                                      <a href="#">
                                        {" "}
                                        <img
                                          src={require("../../../assets/images/registration_imgs/mail.png")}
                                          className="img-fluid"
                                        />
                                      </a>
                                      <a href="#">
                                        <img
                                          src={require("../../../assets/images/registration_imgs/pencil.png")}
                                          className="img-fluid"
                                        />
                                      </a>
                                      <a href="#">
                                        {" "}
                                        <img
                                          src={require("../../../assets/images/registration_imgs/wrong.png")}
                                          className="img-fluid"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="confirm_msg">
                                  <div className="add_info">
                                    <h4>Register Confirmation Message</h4>
                                    <div className="add_info_span">
                                      <a href="#">Add Phone</a>
                                      <a href="#">Add Email</a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="confirm_msg">
                                  <div className="add_info">
                                    <h4>Register Confirmation Message</h4>
                                    <div className="add_info_span">
                                      <a href="#">Add Phone</a>
                                      <a href="#">Add Email</a>
                                    </div>
                                  </div>
                                  <div className="confirm_msg_content mt-4">
                                    <div className="add_info_content">
                                      <img
                                        src={require("../../../assets/images/registration_imgs/msg.png")}
                                        className="img-fluid"
                                      />
                                      <div>
                                        <h4>Immediately</h4>
                                        <p>[Confirmation] Your webinar link</p>
                                      </div>
                                    </div>
                                    <div className="add_info_img">
                                      <a href="#">
                                        {" "}
                                        <img
                                          src={require("../../../assets/images/registration_imgs/mail.png")}
                                          className="img-fluid"
                                        />
                                      </a>
                                      <a href="#">
                                        <img
                                          src={require("../../../assets/images/registration_imgs/pencil.png")}
                                          className="img-fluid"
                                        />
                                      </a>
                                      <a href="#">
                                        {" "}
                                        <img
                                          src={require("../../../assets/images/registration_imgs/wrong.png")}
                                          className="img-fluid"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li className="list_msg">
                            <h4>Post Webinar notifications</h4>
                            <p>
                              Follow up with them to hand them the link to the
                              webinar replay in case they missed it, or remind
                              them of your product and sevices. this is also
                              great opportunity to invite them to another
                              webinar event!
                            </p>
                            <ul className="pre_notification_1">
                              <li>
                                <div className="confirm_msg">
                                  <div className="add_info">
                                    <h4>Post Webinar follow ups</h4>
                                    <div className="add_info_span">
                                      <a href="#">Add Phone</a>
                                      <a href="#">Add Email</a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className="btn-finish">
                <Link to="#" className="primary_btn">
                  <img
                    src={require("../../../assets/images/chevron-right.png")}
                    height="16"
                    width="16"
                    className="img-fluid"
                  />{" "}
                  Finish
                </Link>
              </div>
            </div>
            <Innerfooter />
          </div>
        </div>
      </div>
    </>
  );
};
export default Webinarpage;
