



import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/images/logo.svg";
import { Login_user, resetPassword } from "../action/user.action";
import { toastAlert } from "../utils/toast";
import { setCookies } from "../utils/cookies";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    Alert
} from "react-bootstrap";


const InitialFormValue = {
    newpassword: '',
    confirm_password: ''
}

function ResetPassword() {

    const { id } = useParams()
    const [formValue, setFormValue] = useState(InitialFormValue)
    const [showPwd, setShowPwd] = useState(false)
    const [showconfPwd, setShowconfPwd] = useState(false)
    const location = useLocation()
    const [error, setError] = useState()
    const navigate = useNavigate()
    const { newpassword, confirm_password } = formValue
    const [passmessage, setpassmessage] = useState("");
    const [message, setMessage] = useState("");
    const [progress, setProgress] = useState("");




    const changesHadler = async (e) => {
        try {
            const { id, value } = e.target
            setFormValue({ ...formValue, [id]: value })
            if (id == "newpassword") {
                handlePassword(value)
            }

        } catch (error) {
            console.log(error)
        }
    }


    const Fromvalidation = async () => {
        try {
            var validateError = {};

            if (newpassword.trim() == "") {
                validateError.newpassword = "Password Required";
            } else if (Object.keys(passmessage).length != 0) {
                validateError.newpassword = passmessage;
            }

            if (confirm_password.trim() == "") {
                validateError.confirm_password = "Confirm Password Required";
            } else if (newpassword != confirm_password) {
                validateError.confirm_password =
                    "Password and Confirm Password Does not Match";
            }

            setError(validateError);
            return validateError;
        } catch (err) {
            //console.log(err);
        }
    };


    const submit_Handler = async () => {
        try {

            const check = await Fromvalidation();
            var errorsSize = Object.keys(check).length;
            if (errorsSize == 0) {
                const Payload = {
                    id,
                    ...formValue
                }
                const { status, result } = await resetPassword(Payload)
                if (status) {
                    toastAlert('success', result.message)
                    navigate('/login')
                } else {
                    setError(result.message)
                }
            }
        } catch (error) {
            toastAlert('error', "Something went wrong,please try again later")

        }
    }


    const handlePassword = (passwordValue) => {
        const strengthChecks = {
            length: 0,
            hasUpperCase: false,
            hasLowerCase: false,
            hasDigit: false,
            hasSpecialChar: false,
        };

        strengthChecks.length = passwordValue.length >= 8 ? true : false;
        strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
        strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
        strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
        strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

        var errormsg = {};
        if (!strengthChecks.length) {
            errormsg = "password must contain at least 8 characters";
        } else if (!strengthChecks.hasUpperCase) {
            errormsg = "password must contain at least one uppercase letter";
        } else if (!strengthChecks.hasLowerCase) {
            errormsg = "password must contain at least one lowercase letter";
        } else if (!strengthChecks.hasDigit) {
            errormsg = "password must contain at least one numeric character";
        } else if (!strengthChecks.hasSpecialChar) {
            errormsg = "password must contain at least one special character";
        }
        setpassmessage(errormsg);

        let verifiedList = Object.values(strengthChecks).filter((value) => value);

        let strength =
            verifiedList.length == 5
                ? "Strong"
                : verifiedList.length >= 2
                    ? "Medium"
                    : "Weak";

        // setPassword(passwordValue);
        setProgress(`${(verifiedList.length / 5) * 100}%`);
        setMessage(strength);

        // console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);
    };

    const getActiveColor = (type) => {
        if (type === "Strong") return "#8BC926";
        if (type === "Medium") return "#FEBD01";
        return "red";
    };


    return (
        <>
            <div className="nav_sec">
                <Header />
            </div>

            <section className="login_page">
                <Container>
                    <a href="/">
                        <img
                            src={logo}
                            className="img-fluid brand_logo mx-auto d-block"
                            alt="logo"
                        />
                    </a>

                    <div className="login_content mt-5">
                        <h4 className="text-center">Reset Password</h4>
                        <label for="exampleFormControlInput1" class="form-label">
                            New Password
                        </label>
                        <div>
                            <div class="input-group mb-2">
                                <input
                                    type={showPwd ? "text" : 'password'}
                                    class="form-control"
                                    id='newpassword'
                                    placeholder="Enter your password"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    defaultValue={newpassword}
                                    onChange={changesHadler}
                                />
                                {/* <span class="input-group-text" id="basic-addon2">
                                    {
                                        showPwd ?
                                            <FontAwesomeIcon icon={faEye} className="eye_icon" onClick={() => setShowPwd(!showPwd)} />
                                            :
                                            <FontAwesomeIcon icon={faEyeSlash} className="eye_icon" onClick={() => setShowPwd(!showPwd)} />
                                    }
                                </span> */}

                                <span class="input-group-text" id="basic-addon2" style={{ cursor: "pointer" }}>
                                    {showPwd ? (
                                        <i
                                            className="fa-regular fa-eye-slash"
                                            onClick={() => setShowPwd(false)}
                                        ></i>
                                    ) : (
                                        <i
                                            className="fa-regular fa-eye"
                                            onClick={() => setShowPwd(true)}
                                        ></i>
                                    )}
                                </span>

                            </div>
                            {newpassword.length !== 0 ? (
                                <>
                                    <div className="progress-bg">
                                        <div
                                            className="progress"
                                            style={{
                                                marginTop: "1px",
                                                height: "6px",
                                                width: progress,
                                                backgroundColor: getActiveColor(message),
                                            }}
                                        ></div>
                                    </div>
                                    <p
                                        className="message"
                                        style={{ color: getActiveColor(message) }}
                                    >
                                        Your password is {message}
                                    </p>
                                </>
                            ) : null}
                           
                            {
                                error && error.newpassword ? <span style={{ color: 'red' }}>{error.newpassword}</span> : ''
                            }
                        </div>

                        <label for="exampleFormControlInput1" class="form-label">
                            Confirm Password
                        </label>
                        <div>
                            <div class="input-group mb-2">
                                <input
                                    type={showconfPwd ? "text" : 'password'}
                                    class="form-control"
                                    id='confirm_password'
                                    placeholder="Enter your password"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    defaultValue={confirm_password}
                                    onChange={changesHadler}
                                />


                                <span class="input-group-text" id="basic-addon2" style={{ cursor: "pointer" }}>
                                    {showconfPwd ? (
                                        <i
                                            className="fa-regular fa-eye-slash"
                                            onClick={() => setShowconfPwd(false)}
                                        ></i>
                                    ) : (
                                        <i
                                            className="fa-regular fa-eye"
                                            onClick={() => setShowconfPwd(true)}
                                        ></i>
                                    )}
                                </span>
                            </div>
                            {
                                error && error.confirm_password ? <span style={{ color: 'red' }}>{error.confirm_password}</span> : ''
                            }

                        </div>

                        <button type="button" class="login_button mt-4" onClick={submit_Handler}>
                            Login
                        </button>


                    </div>
                </Container>
            </section >
            <Footer />
        </>
    );
}

export default ResetPassword;
