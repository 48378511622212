import React from "react";
import logo from "../assets/images/footer-logo.svg";

export default function Footer(props) {
  return (
    <footer className="main-footer">
      <div className="container">
        <div className="footer-card">
          <div className="footer-logo">
            <img src={logo} className="img-fluid" alt="logo" />
          </div>
          <ul>
            <li>
              <a href="#">Privacy Policy</a>
            </li>
            <li>
              <a href="#">Terms & Conditions</a>
            </li>
          </ul>
          <p>Copyright 2024, BTX Verse, All rights reserved</p>
          <div className="footer-social">
            <h5>Follow us</h5>
            <a href="#" target="_blank">
              <span>
                <img
                  src={require("../assets/images/insta-icon.png")}
                  alt="img"
                  className="img-fluid"
                />
              </span>{" "}
              Instagram
            </a>
            <a href="#" target="_blank">
              <span>
                <img
                  src={require("../assets/images/facebook-icon.png")}
                  className="img-fluid"
                  alt="face"
                />
              </span>{" "}
              Facebook
            </a>
            <a href="#" target="_blank">
              <span>
                <img
                  src={require("../assets/images/youtube-icon.png")}
                  className="img-fluid"
                  alt="logo"
                />
              </span>{" "}
              Youtube
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
