import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Navinner from "../components/nav-inner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import nextImg from "../assets/images/side-icon.svg";
import nextImg1 from "../assets/images/side-icon2.svg";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Innerpagenavbar from "../components/innerpage-navbar";
import Settingpage from "../components/dashboard/settingpage";
import Webinarpage from "./express/schudule/webinar";
import {
  Create_Webinar,
  create_Meeting,
  delete_Webinar,
  getAllUser_InWebinar,
  getAllWebinar,
  getAll_Complted_meeting,
  searchMeetings,
} from "../action/user.action";
import { useDispatch, useSelector } from "react-redux";
import { encryptString } from "../helper/helper";
import {
  Search_Details,
  setWebinar,
  Webinar_Details,
} from "../redux/features/webinar_details";

import moment, { now } from "moment";

import config from "../lib/config";

import Innerfooter from "../components/inner-footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faShareFromSquare,
  faMagnifyingGlass,
  faListUl,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import Emptymeeting from "../assets/images/Emptymeeting.png";

//COMPONENTS
import ConfirmationModal from "../components/common/modals/ConfirmationModal";
import { toastAlert } from "../utils/toast";
import PropagateLoader from "react-spinners/PropagateLoader";

import { formatInTimeZone } from "date-fns-tz";

const InnerActiveRegistration = () => {
  const [formvalue, setFormvalue] = useState({});
  const [werbibar_details, setWebinarDetails] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    getAllmeetings();
  }, []);

  const getAllmeetings = async () => {
    try {
      const { status, result } = await getAllWebinar();

      console.log(status, result, "sakthi ----- dashboard");

      setWebinarDetails(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  const Submithandler = async () => {
    try {
      console.log(formvalue, "formvalue");
      if (!formvalue.webinar_id) {
        toastAlert("error", "Select Webinar");
        return;
      }

      if (!formvalue.session_id) {
        toastAlert("error", "Select Sesssion");
        return;
      }
      const { result, status } = await getAllUser_InWebinar(formvalue);

      console.log(result, status, "result,status");

      setUserList(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  const selectHandler = async (e) => {

   
    const singleWebinar = werbibar_details.find(
      (val) => val._id == e.target.value
    );

    // console.log()

    setSessions(singleWebinar.sessions);
    setFormvalue({ ...formvalue, webinar_id: e.target.value });
  };

  const sessionselectHandler = async (e) => {
    setFormvalue({ ...formvalue, session_id: e.target.value });
  };
  console.log(werbibar_details, "werbibar_details");

  const convertTime = (sessiondate, timezone) => {
    console.log(
      sessiondate,
      timezone,
      "hhhhhhhhhhhhhhhhhhhhhhhh------------------"
    );

    const pacificMidwayDateTime = moment.tz(
      new Date(sessiondate),
      "MM/DD/YYYY hh:mm A",
      timezone
    );

    console.log(
      pacificMidwayDateTime.format("MM/DD/YYYY hh:mm A"),
      "istDateTime1"
    );

    return pacificMidwayDateTime.format("MM/DD/YYYY hh:mm A");
  };

  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard">
          <Innerpagenavbar />

          <div className="dashbord-right mt-5 mb-5">
            <div className="dashbord-right-body-1 dashboard-padding ">
              <div className="inner_home_shadow">
                <div className="dashbord-right-header inner_home_dashboard ">
                  <p>My active registrants</p>
                </div>
                <div className="analytics_select inner_select_box mt-4">
                  <div className="select_box">
                    <label for="exampleFormControlInput1" class="form-label">
                      Webinar
                    </label>

                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={selectHandler}
                    >
                      <option selected>Open this select menu</option>

                      {werbibar_details.map((val) => (
                        <option value={val._id}>{val.title}</option>
                      ))}
                      {/* <option value="3">Three</option> */}
                    </select>
                  </div>
                  <div className="select_box">
                    <label for="exampleFormControlInput1" class="form-label">
                      Session
                    </label>

                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={sessionselectHandler}
                      // value={}
                    >
                      <option>Select Session</option>
                      {sessions?.length > 0 &&
                        sessions.map((val) => (
                          <option value={val._id}>
                            {convertTime(val.startDate, val.timeZone)}
                          </option>
                        ))}
                    </select>
                  </div>

                  {/* <div className="select_box">
                    <label for="exampleFormControlInput1" class="form-label">
                      Session
                    </label>

                    <select
                      class="form-select"
                      aria-label="Default select example"

                      // value={}
                    >
                      <option value={1}>Select Session</option>
                    </select>
                  </div> */}

                  <Link to="#" className="primary_btn" onClick={Submithandler}>
                    <img
                      src={require("../assets/images/chevron-right.png")}
                      height="16"
                      width="16"
                      className="img-fluid"
                    />
                    Go
                  </Link>
                </div>
                <div className="inner_active_body">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>User name</th>
                          <th>Mail Id</th>
                          <th>Joined at</th>
                          <th>Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userList.length > 0 ? (
                          <>
                            {userList.map((val) => (
                              <tr>
                                <td>{val.username}</td>

                                <td>{val.mailid}</td>
                                <td>
                                  {moment(val.registerdate).format("LLL")}
                                </td>
                                <td>{val.Duration}</td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <>No User Registers</>
                        )}

                        {/* <tr>
                          <td>John</td>

                          <td>john@example.com</td>
                          <td>4 Jun 2024 12:04:56 AM</td>
                          <td>2 Hrs</td>
                        </tr>
                        <tr>
                          <td>John</td>

                          <td>john@example.com</td>
                          <td>4 Jun 2024 12:04:56 AM</td>
                          <td>2 Hrs</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Innerfooter />
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InnerActiveRegistration;
