import moment from "moment";
import { isEmpty } from "../helper/helper";

export const persenter_validation = async (value) => {
  console.log(value, "newPresenter");
  console.log(value.Presenters_email, "newPresenter-----------111111111");
  const email = /[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/;
  const error = {};

  if (isEmpty(value.Presenters_name)) {
    error.Presenters_name = "Presenters Name Required";
  }
  if (isEmpty(value.Presenters_email)) {
    error.Presenters_email = "Presenters Email Required";
  } else if (!email.test(value.Presenters_email)) {
    error.Presenters_email = "Invalid Email";
  }

  if (value.isOTPSent && isEmpty(value.otp)) {
    error.Presenters_otp = "OTP Required";
  }
  return error;
};

export const Basic_validation = async (value) => {
  const error = {};

  if (isEmpty(value.Webinar_description)) {
    error.Webinar_description = "Webinar Description Required";
  }
  // if (isEmpty(value.Webinar_language)) {
  //     error.Webinar_language = 'Webinar Language Required'
  // }
  if (isEmpty(value.Webinar_title)) {
    error.Webinar_title = "Webinar Title Required";
  }
  return error;
};

export const new_Basic_validation = async (value) => {
  const error = {};

  console.log(value, "value");
  if (isEmpty(value.discription)) {
    error.Webinar_description = "Webinar Description Required";
  }
  // if (isEmpty(value.language)) {
  //     error.Webinar_language = 'Webinar Language Required'
  // }
  if (isEmpty(value.title)) {
    error.Webinar_title = "Webinar Title Required";
  }
  return error;
};

export const session_singleValid = async (value) => {
  console.log(value, "session_singleValid-----session_singleValid");

  console.log(value?.edit, "editediteditedit");
  console.log(new Date(value.session_date), "value");

  const presentDate = new Date();

  const dateString = new Date(
    value.AddedSession[value.AddedSession.length - 1]?.session_date
  );

  const afer_30 = dateString.setMinutes(dateString.getMinutes() + 30);

  let sessionDateMoment = new Date(value.session_date);

  sessionDateMoment = sessionDateMoment.setMinutes(
    sessionDateMoment.getMinutes() + 0
  );

  console.log(afer_30, sessionDateMoment, "presentDate-----presentDate");

  const error = {};

  if (value.session_date == undefined) {
    error.session_date = "Session Date Required";
  }
  if (presentDate > new Date(value.session_date)) {
    error.sessionTime = "Must greater then present time";
  }
  if (isEmpty(value.edit) && sessionDateMoment < afer_30) {
    error.sessionTime =
      "Next Session must be 30 mins greater then previous Session ";
  }
  if (isEmpty(value.timezone) || value.timezone == "Select time zone") {
    error.timezone = "Timezone Required";
  }

  if (!isEmpty(value.edit)) {
    const beforedateString = new Date(
      value.AddedSession[value.edit - 1]?.session_date
    );

    const before_date = beforedateString.setMinutes(
      beforedateString.getMinutes() + 30
    );

    const AfterdateString = new Date(
      value.AddedSession[value.edit + 1]?.session_date
    );

    const after_date = AfterdateString.setMinutes(
      AfterdateString.getMinutes() - 30
    );

    let sessionDateMoment = new Date(value.session_date);

    sessionDateMoment = sessionDateMoment.setMinutes(
      sessionDateMoment.getMinutes() + 0
    );
    console.log(before_date, "before_date", after_date, sessionDateMoment);

    if (sessionDateMoment < before_date) {
      error.sessionTime = "Must greater then previous session + 30 minutes ";
    }
    if (sessionDateMoment > after_date) {
      error.sessionTime = "Must less then next session - 30 minutes ";
    }
  }
  return error;
};

export const Full_session_singleValid = async (value) => {
  console.log(value, "session_singleValid-----session_singleValid");
  console.log(new Date(value.Currentsession.session_date), "value");

  const presentDate = new Date();

  const dateString = new Date(
    value.webinar_sessions[value.webinar_sessions.length - 1]?.session_date
  );

  const afer_30 = dateString.setMinutes(dateString.getMinutes() + 30);

  let sessionDateMoment = new Date(value.Currentsession.session_date);

  sessionDateMoment = sessionDateMoment.setMinutes(
    sessionDateMoment.getMinutes() + 0
  );

  console.log(
    presentDate,
    new Date(value.Currentsession.session_date),
    "presentDate-----presentDate"
  );

  const error = {};
  if (value.Currentsession.session_date == undefined) {
    error.session_date = "Required";
  }
  if (presentDate > new Date(value.Currentsession.session_date)) {
    error.sessionTime = "Must greater then present time";
  }
  if (isEmpty(value.edit) && sessionDateMoment < afer_30) {
    error.sessionTime =
      "Next Session must be 30 mins greater then previous Session ";
  }

  if (!isEmpty(value.edit)) {
    const beforedateString = new Date(
      value.webinar_sessions[value.edit - 1]?.session_date
    );

    const before_date = beforedateString.setMinutes(
      beforedateString.getMinutes() + 30
    );

    const AfterdateString = new Date(
      value.webinar_sessions[value.edit + 1]?.session_date
    );

    const after_date = AfterdateString.setMinutes(
      AfterdateString.getMinutes() - 30
    );

    let sessionDateMoment = new Date(value.Currentsession.session_date);

    sessionDateMoment = sessionDateMoment.setMinutes(
      sessionDateMoment.getMinutes() + 0
    );
    console.log(before_date, "before_date", after_date, sessionDateMoment);

    if (sessionDateMoment < before_date) {
      error.sessionTime = "Must greater then previous session  + 30 minutes ";
    }
    if (sessionDateMoment > after_date) {
      error.sessionTime = "Must less then next session - 30 minutes ";
    }
  }

  if (
    isEmpty(value.Currentsession.timezone) ||
    value.Currentsession.timezone == "Select time zone"
  ) {
    error.timezone = "Required";
  }
  return error;
};

export const session_recurringValid = async (value) => {
  console.log(value, "value");
  let error = {};
  console.log(value, "value111111");

  if (value.recurringLimt == 0 && value.NoOfSession == "") {
    error.NoOfSession = "Required";
  }
  // if (value.recurringLimt == 0 && value.NoOfSession == "") {
  //   error.NoOfSession = "Limit Required";
  // }
  if (isEmpty(value.Day) || value.Day == "Day" || value.Day == "") {
    error.Day = "Day Required";
  }
  if (isEmpty(value.timezone) || value.timezone == "Select time zone") {
    error.timezone = "Required";
  }
  console.log(error, "error");
  return error;
};

export const schedule_Valid = async (value) => {
  console.log(value, "value");
  const error = {};
  const email = /[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/;
  if (isEmpty(value.email)) {
    error.schedule_email = "Email Required";
  }
  if (isEmpty(value.event)) {
    error.schedule_event = "Event Required";
  }
  if (isEmpty(value.mobile)) {
    error.schedule_mobile = "Mobile Required";
  }
  if (isEmpty(value.name)) {
    error.schedule_name = "Name Required";
  }
  if (!email.test(value.email)) {
    error.schedule_email = "Email Invalid";
  }
  return error;
};

export const registervalidation = async (RegisterForm) => {
  const error = {};
  if (RegisterForm.hasPassword && isEmpty(RegisterForm.masterpassword)) {
    error.password = "Password Required";
  }
  if (RegisterForm.hasRegistration && RegisterForm.registrationFees == 0) {
    error.registrationFees = "Registration Fees Required";
  }
  if (RegisterForm.hasNotified && isEmpty(RegisterForm.notiMailId)) {
    error.notiMailId = "NotiMailId Required";
  }
  return error;
};
